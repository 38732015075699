import fаmilyApаrtmentOсeаnisPrew001 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-001.jpg';
import fаmilyApаrtmentOсeаnisPrew002 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-002.jpg';
import fаmilyApаrtmentOсeаnisPrew003 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-003.jpg';
import fаmilyApаrtmentOсeаnisPrew004 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-004.jpg';
import fаmilyApаrtmentOсeаnisPrew005 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-005.jpg';
import fаmilyApаrtmentOсeаnisPrew006 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-006.jpg';
import fаmilyApаrtmentOсeаnisPrew007 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-007.jpg';
import fаmilyApаrtmentOсeаnisPrew008 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-008.jpg';
import fаmilyApаrtmentOсeаnisPrew009 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-009.jpg';
import fаmilyApаrtmentOсeаnisPrew010 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-010.jpg';
import fаmilyApаrtmentOсeаnisPrew011 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-011.jpg';
import fаmilyApаrtmentOсeаnisPrew012 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-012.jpg';
import fаmilyApаrtmentOсeаnisPrew013 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-013.jpg';
import fаmilyApаrtmentOсeаnisPrew014 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-014.jpg';
import fаmilyApаrtmentOсeаnisPrew015 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-015.jpg';
import fаmilyApаrtmentOсeаnisPrew016 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-016.jpg';
import fаmilyApаrtmentOсeаnisPrew017 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-017.jpg';
import fаmilyApаrtmentOсeаnisPrew018 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-018.jpg';
import fаmilyApаrtmentOсeаnisPrew019 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-019.jpg';
import fаmilyApаrtmentOсeаnisPrew020 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-020.jpg';
import fаmilyApаrtmentOсeаnisPrew021 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-021.jpg';
import fаmilyApаrtmentOсeаnisPrew022 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-022.jpg';
import fаmilyApаrtmentOсeаnisPrew023 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-023.jpg';
import fаmilyApаrtmentOсeаnisPrew024 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-024.jpg';
import fаmilyApаrtmentOсeаnisPrew025 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-025.jpg';
import fаmilyApаrtmentOсeаnisPrew026 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-026.jpg';
import fаmilyApаrtmentOсeаnisPrew027 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-027.jpg';
import fаmilyApаrtmentOсeаnisPrew028 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-028.jpg';
import fаmilyApаrtmentOсeаnisPrew029 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-029.jpg';
import fаmilyApаrtmentOсeаnisPrew030 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-030.jpg';
import fаmilyApаrtmentOсeаnisPrew031 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-031.jpg';
import fаmilyApаrtmentOсeаnisPrew032 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-032.jpg';
import fаmilyApаrtmentOсeаnisPrew033 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-033.jpg';
import fаmilyApаrtmentOсeаnisPrew034 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-034.jpg';
import fаmilyApаrtmentOсeаnisPrew035 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-035.jpg';
import fаmilyApаrtmentOсeаnisPrew036 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-036.jpg';
import fаmilyApаrtmentOсeаnisPrew037 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-prw/fаmily-аpаrtment-oсeаnis-prew-037.jpg';

import fаmilyApаrtmentOсeаnis001 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-001.webp';
import fаmilyApаrtmentOсeаnis002 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-002.webp';
import fаmilyApаrtmentOсeаnis003 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-003.webp';
import fаmilyApаrtmentOсeаnis004 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-004.webp';
import fаmilyApаrtmentOсeаnis005 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-005.webp';
import fаmilyApаrtmentOсeаnis006 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-006.webp';
import fаmilyApаrtmentOсeаnis007 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-007.webp';
import fаmilyApаrtmentOсeаnis008 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-008.webp';
import fаmilyApаrtmentOсeаnis009 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-009.webp';
import fаmilyApаrtmentOсeаnis010 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-010.webp';
import fаmilyApаrtmentOсeаnis011 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-011.webp';
import fаmilyApаrtmentOсeаnis012 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-012.webp';
import fаmilyApаrtmentOсeаnis013 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-013.webp';
import fаmilyApаrtmentOсeаnis014 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-014.webp';
import fаmilyApаrtmentOсeаnis015 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-015.webp';
import fаmilyApаrtmentOсeаnis016 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-016.webp';
import fаmilyApаrtmentOсeаnis017 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-017.webp';
import fаmilyApаrtmentOсeаnis018 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-018.webp';
import fаmilyApаrtmentOсeаnis019 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-019.webp';
import fаmilyApаrtmentOсeаnis020 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-020.webp';
import fаmilyApаrtmentOсeаnis021 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-021.webp';
import fаmilyApаrtmentOсeаnis022 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-022.webp';
import fаmilyApаrtmentOсeаnis023 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-023.webp';
import fаmilyApаrtmentOсeаnis024 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-024.webp';
import fаmilyApаrtmentOсeаnis025 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-025.webp';
import fаmilyApаrtmentOсeаnis026 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-026.webp';
import fаmilyApаrtmentOсeаnis027 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-027.webp';
import fаmilyApаrtmentOсeаnis028 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-028.webp';
import fаmilyApаrtmentOсeаnis029 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-029.webp';
import fаmilyApаrtmentOсeаnis030 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-030.webp';
import fаmilyApаrtmentOсeаnis031 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-031.webp';
import fаmilyApаrtmentOсeаnis032 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-032.webp';
import fаmilyApаrtmentOсeаnis033 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-033.webp';
import fаmilyApаrtmentOсeаnis034 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-034.webp';
import fаmilyApаrtmentOсeаnis035 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-035.webp';
import fаmilyApаrtmentOсeаnis036 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-036.webp';
import fаmilyApаrtmentOсeаnis037 from '../../img/gallery-apartments/fаmily-аpаrtment-oсeаnis/fаmily-аpаrtment-oсeаnis-037.webp';

export const fаmilyApаrtmentOсeаnis = [
  { key: 'fаmilyApаrtmentOсeаnis001', image: fаmilyApаrtmentOсeаnis001, preview: fаmilyApаrtmentOсeаnisPrew001, },
  { key: 'fаmilyApаrtmentOсeаnis002', image: fаmilyApаrtmentOсeаnis002, preview: fаmilyApаrtmentOсeаnisPrew002, },
  { key: 'fаmilyApаrtmentOсeаnis003', image: fаmilyApаrtmentOсeаnis003, preview: fаmilyApаrtmentOсeаnisPrew003, },
  { key: 'fаmilyApаrtmentOсeаnis004', image: fаmilyApаrtmentOсeаnis004, preview: fаmilyApаrtmentOсeаnisPrew004, },
  { key: 'fаmilyApаrtmentOсeаnis005', image: fаmilyApаrtmentOсeаnis005, preview: fаmilyApаrtmentOсeаnisPrew005, },
  { key: 'fаmilyApаrtmentOсeаnis006', image: fаmilyApаrtmentOсeаnis006, preview: fаmilyApаrtmentOсeаnisPrew006, },
  { key: 'fаmilyApаrtmentOсeаnis007', image: fаmilyApаrtmentOсeаnis007, preview: fаmilyApаrtmentOсeаnisPrew007, },
  { key: 'fаmilyApаrtmentOсeаnis008', image: fаmilyApаrtmentOсeаnis008, preview: fаmilyApаrtmentOсeаnisPrew008, },
  { key: 'fаmilyApаrtmentOсeаnis009', image: fаmilyApаrtmentOсeаnis009, preview: fаmilyApаrtmentOсeаnisPrew009, },
  { key: 'fаmilyApаrtmentOсeаnis010', image: fаmilyApаrtmentOсeаnis010, preview: fаmilyApаrtmentOсeаnisPrew010, },
  { key: 'fаmilyApаrtmentOсeаnis011', image: fаmilyApаrtmentOсeаnis011, preview: fаmilyApаrtmentOсeаnisPrew011, },
  { key: 'fаmilyApаrtmentOсeаnis012', image: fаmilyApаrtmentOсeаnis012, preview: fаmilyApаrtmentOсeаnisPrew012, },
  { key: 'fаmilyApаrtmentOсeаnis013', image: fаmilyApаrtmentOсeаnis013, preview: fаmilyApаrtmentOсeаnisPrew013, },
  { key: 'fаmilyApаrtmentOсeаnis014', image: fаmilyApаrtmentOсeаnis014, preview: fаmilyApаrtmentOсeаnisPrew014, },
  { key: 'fаmilyApаrtmentOсeаnis015', image: fаmilyApаrtmentOсeаnis015, preview: fаmilyApаrtmentOсeаnisPrew015, },
  { key: 'fаmilyApаrtmentOсeаnis016', image: fаmilyApаrtmentOсeаnis016, preview: fаmilyApаrtmentOсeаnisPrew016, },
  { key: 'fаmilyApаrtmentOсeаnis017', image: fаmilyApаrtmentOсeаnis017, preview: fаmilyApаrtmentOсeаnisPrew017, },
  { key: 'fаmilyApаrtmentOсeаnis018', image: fаmilyApаrtmentOсeаnis018, preview: fаmilyApаrtmentOсeаnisPrew018, },
  { key: 'fаmilyApаrtmentOсeаnis019', image: fаmilyApаrtmentOсeаnis019, preview: fаmilyApаrtmentOсeаnisPrew019, },
  { key: 'fаmilyApаrtmentOсeаnis020', image: fаmilyApаrtmentOсeаnis020, preview: fаmilyApаrtmentOсeаnisPrew020, },
  { key: 'fаmilyApаrtmentOсeаnis021', image: fаmilyApаrtmentOсeаnis021, preview: fаmilyApаrtmentOсeаnisPrew021, },
  { key: 'fаmilyApаrtmentOсeаnis022', image: fаmilyApаrtmentOсeаnis022, preview: fаmilyApаrtmentOсeаnisPrew022, },
  { key: 'fаmilyApаrtmentOсeаnis023', image: fаmilyApаrtmentOсeаnis023, preview: fаmilyApаrtmentOсeаnisPrew023, },
  { key: 'fаmilyApаrtmentOсeаnis024', image: fаmilyApаrtmentOсeаnis024, preview: fаmilyApаrtmentOсeаnisPrew024, },
  { key: 'fаmilyApаrtmentOсeаnis025', image: fаmilyApаrtmentOсeаnis025, preview: fаmilyApаrtmentOсeаnisPrew025, },
  { key: 'fаmilyApаrtmentOсeаnis026', image: fаmilyApаrtmentOсeаnis026, preview: fаmilyApаrtmentOсeаnisPrew026, },
  { key: 'fаmilyApаrtmentOсeаnis027', image: fаmilyApаrtmentOсeаnis027, preview: fаmilyApаrtmentOсeаnisPrew027, },
  { key: 'fаmilyApаrtmentOсeаnis028', image: fаmilyApаrtmentOсeаnis028, preview: fаmilyApаrtmentOсeаnisPrew028, },
  { key: 'fаmilyApаrtmentOсeаnis029', image: fаmilyApаrtmentOсeаnis029, preview: fаmilyApаrtmentOсeаnisPrew029, },
  { key: 'fаmilyApаrtmentOсeаnis030', image: fаmilyApаrtmentOсeаnis030, preview: fаmilyApаrtmentOсeаnisPrew030, },
  { key: 'fаmilyApаrtmentOсeаnis031', image: fаmilyApаrtmentOсeаnis031, preview: fаmilyApаrtmentOсeаnisPrew031, },
  { key: 'fаmilyApаrtmentOсeаnis032', image: fаmilyApаrtmentOсeаnis032, preview: fаmilyApаrtmentOсeаnisPrew032, },
  { key: 'fаmilyApаrtmentOсeаnis033', image: fаmilyApаrtmentOсeаnis033, preview: fаmilyApаrtmentOсeаnisPrew033, },
  { key: 'fаmilyApаrtmentOсeаnis034', image: fаmilyApаrtmentOсeаnis034, preview: fаmilyApаrtmentOсeаnisPrew034, },
  { key: 'fаmilyApаrtmentOсeаnis035', image: fаmilyApаrtmentOсeаnis035, preview: fаmilyApаrtmentOсeаnisPrew035, },
  { key: 'fаmilyApаrtmentOсeаnis036', image: fаmilyApаrtmentOсeаnis036, preview: fаmilyApаrtmentOсeаnisPrew036, },
  { key: 'fаmilyApаrtmentOсeаnis037', image: fаmilyApаrtmentOсeаnis037, preview: fаmilyApаrtmentOсeаnisPrew037, },
]
