import React from 'react';

const Offer = () => {
  return (
    <div>
      Offer
    </div>
  );
}

export default Offer;
