import muzaApаrtment001 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-001.jpg';
import muzaApаrtment002 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-002.jpg';
import muzaApаrtment003 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-003.jpg';
import muzaApаrtment004 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-004.jpg';
import muzaApаrtment005 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-005.jpg';
import muzaApаrtment006 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-006.jpg';
import muzaApаrtment007 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-007.jpg';
import muzaApаrtment008 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-008.jpg';
import muzaApаrtment009 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-009.jpg';
import muzaApаrtment010 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-010.jpg';
import muzaApаrtment011 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-011.jpg';
import muzaApаrtment012 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-012.jpg';
import muzaApаrtment013 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-013.jpg';
import muzaApаrtment014 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-014.jpg';
import muzaApаrtment015 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-015.jpg';
import muzaApаrtment016 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-016.jpg';
import muzaApаrtment017 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-017.jpg';
import muzaApаrtment018 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-018.jpg';
import muzaApаrtment019 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-019.jpg';
import muzaApаrtment020 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-020.jpg';
import muzaApаrtment021 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-021.jpg';
import muzaApаrtment022 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-022.jpg';
import muzaApаrtment023 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-023.jpg';
import muzaApаrtment024 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-024.jpg';
import muzaApаrtment025 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-025.jpg';
import muzaApаrtment026 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-026.jpg';
import muzaApаrtment027 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-027.jpg';
import muzaApаrtment028 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-028.jpg';
import muzaApаrtment029 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-029.jpg';
import muzaApаrtment030 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-030.jpg';
import muzaApаrtment031 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-031.jpg';
import muzaApаrtment032 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-032.jpg';
import muzaApаrtment033 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-033.jpg';
import muzaApаrtment034 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-034.jpg';
import muzaApаrtment035 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-035.jpg';
import muzaApаrtment036 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-036.jpg';

import muzaApаrtmentPrew001 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-001.jpg';
import muzaApаrtmentPrew002 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-002.jpg';
import muzaApаrtmentPrew003 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-003.jpg';
import muzaApаrtmentPrew004 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-004.jpg';
import muzaApаrtmentPrew005 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-005.jpg';
import muzaApаrtmentPrew006 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-006.jpg';
import muzaApаrtmentPrew007 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-007.jpg';
import muzaApаrtmentPrew008 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-008.jpg';
import muzaApаrtmentPrew009 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-009.jpg';
import muzaApаrtmentPrew010 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-010.jpg';
import muzaApаrtmentPrew011 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-011.jpg';
import muzaApаrtmentPrew012 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-012.jpg';
import muzaApаrtmentPrew013 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-013.jpg';
import muzaApаrtmentPrew014 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-014.jpg';
import muzaApаrtmentPrew015 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-015.jpg';
import muzaApаrtmentPrew016 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-016.jpg';
import muzaApаrtmentPrew017 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-017.jpg';
import muzaApаrtmentPrew018 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-018.jpg';
import muzaApаrtmentPrew019 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-019.jpg';
import muzaApаrtmentPrew020 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-020.jpg';
import muzaApаrtmentPrew021 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-021.jpg';
import muzaApаrtmentPrew022 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-022.jpg';
import muzaApаrtmentPrew023 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-023.jpg';
import muzaApаrtmentPrew024 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-024.jpg';
import muzaApаrtmentPrew025 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-025.jpg';
import muzaApаrtmentPrew026 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-026.jpg';
import muzaApаrtmentPrew027 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-027.jpg';
import muzaApаrtmentPrew028 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-028.jpg';
import muzaApаrtmentPrew029 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-029.jpg';
import muzaApаrtmentPrew030 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-030.jpg';
import muzaApаrtmentPrew031 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-031.jpg';
import muzaApаrtmentPrew032 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-032.jpg';
import muzaApаrtmentPrew033 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-033.jpg';
import muzaApаrtmentPrew034 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-034.jpg';
import muzaApаrtmentPrew035 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-035.jpg';
import muzaApаrtmentPrew036 from '../../img/gallery-apartments/muza-аpаrtment/muza-аpаrtment-prw/muza-аpаrtment-prew-036.jpg';

export const muzaApаrtment = [
  { key: 'muzaApаrtment001', image: muzaApаrtment001, preview: muzaApаrtmentPrew001, },
  { key: 'muzaApаrtment002', image: muzaApаrtment002, preview: muzaApаrtmentPrew002, },
  { key: 'muzaApаrtment003', image: muzaApаrtment003, preview: muzaApаrtmentPrew003, },
  { key: 'muzaApаrtment004', image: muzaApаrtment004, preview: muzaApаrtmentPrew004, },
  { key: 'muzaApаrtment005', image: muzaApаrtment005, preview: muzaApаrtmentPrew005, },
  { key: 'muzaApаrtment006', image: muzaApаrtment006, preview: muzaApаrtmentPrew006, },
  { key: 'muzaApаrtment007', image: muzaApаrtment007, preview: muzaApаrtmentPrew007, },
  { key: 'muzaApаrtment008', image: muzaApаrtment008, preview: muzaApаrtmentPrew008, },
  { key: 'muzaApаrtment009', image: muzaApаrtment009, preview: muzaApаrtmentPrew009, },
  { key: 'muzaApаrtment010', image: muzaApаrtment010, preview: muzaApаrtmentPrew010, },
  { key: 'muzaApаrtment011', image: muzaApаrtment011, preview: muzaApаrtmentPrew011, },
  { key: 'muzaApаrtment012', image: muzaApаrtment012, preview: muzaApаrtmentPrew012, },
  { key: 'muzaApаrtment013', image: muzaApаrtment013, preview: muzaApаrtmentPrew013, },
  { key: 'muzaApаrtment014', image: muzaApаrtment014, preview: muzaApаrtmentPrew014, },
  { key: 'muzaApаrtment015', image: muzaApаrtment015, preview: muzaApаrtmentPrew015, },
  { key: 'muzaApаrtment016', image: muzaApаrtment016, preview: muzaApаrtmentPrew016, },
  { key: 'muzaApаrtment017', image: muzaApаrtment017, preview: muzaApаrtmentPrew017, },
  { key: 'muzaApаrtment018', image: muzaApаrtment018, preview: muzaApаrtmentPrew018, },
  { key: 'muzaApаrtment019', image: muzaApаrtment019, preview: muzaApаrtmentPrew019, },
  { key: 'muzaApаrtment020', image: muzaApаrtment020, preview: muzaApаrtmentPrew020, },
  { key: 'muzaApаrtment021', image: muzaApаrtment021, preview: muzaApаrtmentPrew021, },
  { key: 'muzaApаrtment022', image: muzaApаrtment022, preview: muzaApаrtmentPrew022, },
  { key: 'muzaApаrtment023', image: muzaApаrtment023, preview: muzaApаrtmentPrew023, },
  { key: 'muzaApаrtment024', image: muzaApаrtment024, preview: muzaApаrtmentPrew024, },
  { key: 'muzaApаrtment025', image: muzaApаrtment025, preview: muzaApаrtmentPrew025, },
  { key: 'muzaApаrtment026', image: muzaApаrtment026, preview: muzaApаrtmentPrew026, },
  { key: 'muzaApаrtment027', image: muzaApаrtment027, preview: muzaApаrtmentPrew027, },
  { key: 'muzaApаrtment028', image: muzaApаrtment028, preview: muzaApаrtmentPrew028, },
  { key: 'muzaApаrtment029', image: muzaApаrtment029, preview: muzaApаrtmentPrew029, },
  { key: 'muzaApаrtment030', image: muzaApаrtment030, preview: muzaApаrtmentPrew030, },
  { key: 'muzaApаrtment031', image: muzaApаrtment031, preview: muzaApаrtmentPrew031, },
  { key: 'muzaApаrtment032', image: muzaApаrtment032, preview: muzaApаrtmentPrew032, },
  { key: 'muzaApаrtment033', image: muzaApаrtment033, preview: muzaApаrtmentPrew033, },
  { key: 'muzaApаrtment034', image: muzaApаrtment034, preview: muzaApаrtmentPrew034, },
  { key: 'muzaApаrtment035', image: muzaApаrtment035, preview: muzaApаrtmentPrew035, },
  { key: 'muzaApаrtment036', image: muzaApаrtment036, preview: muzaApаrtmentPrew036, },

]