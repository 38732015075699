import { sourcePersons } from '../sourcePersons';

export const floraApartmentRecommendations = [
  {
    key: 'floraApartmentRecommendations001', image: sourcePersons,
    text: 'Прекрасная квартира в отличной локации! Есть все необходимое, обязательно приедем снова) ',
  },
  {
    key: 'floraApartmentRecommendations002', image: sourcePersons,
    text: 'Очень приятная и уютная квартира! Удобное местоположение! Очень чисто!',
  },
  {
    key: 'floraApartmentRecommendations003', image: sourcePersons,
    text: 'Замечательная квартира, в жизни выглядит также, как на фото. В ней хочется проводить время и рассматривать все детали интерьера. Все сделано со вкусом. Особенно приятно, что продуманы все мелочи для комфортного проживания: гели, шампуни, кондиционеры, даже одноразовые щетки с пастами и плойка для завивки волос. В общем, тут есть все необходимое и даже больше) на кухне еще стоит колонка и лежат настолки, все, чтобы провести приятный вечер) отдельно спасибо Екатерине, которая была всегда на связи, отвечала на любые вопросы и помогала) точно вернемся сюда еще!',
  },
  {
    key: 'floraApartmentRecommendations004', image: sourcePersons,
    text: 'Очень красивая квартира, все как на фото. Есть все для комфортного пребывания. Расположение супер! До всех достопримечательностей ходили пешком.',
  },
  {
    key: 'floraApartmentRecommendations005', image: sourcePersons,
    text: 'Хорошая, чистая квартира. Со всеми удобствами. Находится в самом центре Нижнего Новгорода. Однозначно рекомендую! Никаких проблем с заселением и с доп.услугами🤝',
  },
  {
    key: 'floraApartmentRecommendations006', image: sourcePersons,
    text: 'Проживание состоялось. Квартира чистая, красивая, уютная. Сервис отличный. Хорошее место расположение. Рекомендую!',
  },
  {
    key: 'floraApartmentRecommendations007', image: sourcePersons,
    text: 'Квартира очень понравилась, даже лучше, чем ожидали 🥰 Приятно и хочется в ней находиться. По комфорту очень отличается от большинства квартир для аренды) Очень чистая, уютная. Есть все необходимое и даже больше: гель, шампунь, кондиционер, даже скраб для тела. Если приехал на легке - это очень удобно) Можно постирать вещи, даже сушилка есть.Есть чай и конфетки - после целого дня прогулки по городу это пришлось очень кстати 😅 Думаю, если еще приедем в Нижний, то остановимся у этого хозяина.',
  },
  {
    key: 'floraApartmentRecommendations008', image: sourcePersons,
    text: 'Прекрасная стильная квартира, все необходимое для комфортного проживания имеется, и даже больше! Настольные игры, несколько интересных книг и ненавязчивый декор. Автоматическое заселение и выселение. Свободная парковка во дворе, ключи от шлагбаума были. Никаких проблем не возникло. Екатерина всегда была на связи.Благодарю за качественные услуги!',
  },
  {
    key: 'floraApartmentRecommendations009', image: sourcePersons,
    text: 'Квартира очень понравилась. Замечательное месторасположение, свободная бесплатная парковка на улице + внутренняя закрытая парковка во дворе. Очень хороший ремонт в квартире, интересная планировка, качественная бытовая техника. Бесконтактный заезд в любое время по коду на домофоне и коду на двери квартиры (не надо ждать кого-то, кто придёт с ключами).',
  },
  {
    key: 'floraApartmentRecommendations010', image: sourcePersons,
    text: 'Квартира отличная. Есть все удобства. Все по-честному. Потраченных денег не жаль',
  },
  {
    key: 'floraApartmentRecommendations011', image: sourcePersons,
    text: 'Очень красивая уютная и стильная квартира . Снимали на 1,5 суток. Есть все необходимое(посуда / техника / полотенца / шампуньки / чай печеньки - даже была Bluetooth колонка. Хозяйка всегда на связи и оперативно отвечает на все вопросы. Мы очень довольны',
  },
  {
    key: 'floraApartmentRecommendations012', image: sourcePersons,
    text: 'Квартира отличная, идеально подойдет для 2 семей, мы как раз искали, чтобы разместиться с родственниками. Все сделано как для себя, а не для аренды. Приедем еще раз',
  },
];