import goodVibesOnlyApartmentPrew001 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-001.jpg';
import goodVibesOnlyApartmentPrew002 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-002.jpg';
import goodVibesOnlyApartmentPrew003 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-003.jpg';
import goodVibesOnlyApartmentPrew004 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-004.jpg';
import goodVibesOnlyApartmentPrew005 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-005.jpg';
import goodVibesOnlyApartmentPrew006 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-006.jpg';
import goodVibesOnlyApartmentPrew007 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-007.jpg';
import goodVibesOnlyApartmentPrew008 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-008.jpg';
import goodVibesOnlyApartmentPrew009 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-009.jpg';
import goodVibesOnlyApartmentPrew010 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-010.jpg';
import goodVibesOnlyApartmentPrew011 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-011.jpg';
import goodVibesOnlyApartmentPrew012 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-012.jpg';
import goodVibesOnlyApartmentPrew013 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-013.jpg';
import goodVibesOnlyApartmentPrew014 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-014.jpg';
import goodVibesOnlyApartmentPrew015 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-015.jpg';
import goodVibesOnlyApartmentPrew016 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-016.jpg';
import goodVibesOnlyApartmentPrew017 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-017.jpg';
import goodVibesOnlyApartmentPrew018 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-018.jpg';
import goodVibesOnlyApartmentPrew019 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-019.jpg';
import goodVibesOnlyApartmentPrew020 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-020.jpg';
import goodVibesOnlyApartmentPrew021 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-021.jpg';
import goodVibesOnlyApartmentPrew022 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-022.jpg';
import goodVibesOnlyApartmentPrew023 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-023.jpg';
import goodVibesOnlyApartmentPrew024 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-024.jpg';
import goodVibesOnlyApartmentPrew025 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-025.jpg';
import goodVibesOnlyApartmentPrew026 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-026.jpg';
import goodVibesOnlyApartmentPrew027 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-027.jpg';
import goodVibesOnlyApartmentPrew028 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-028.jpg';
import goodVibesOnlyApartmentPrew029 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-029.jpg';
import goodVibesOnlyApartmentPrew030 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-030.jpg';
import goodVibesOnlyApartmentPrew031 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-031.jpg';
import goodVibesOnlyApartmentPrew032 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-032.jpg';
import goodVibesOnlyApartmentPrew033 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-033.jpg';
import goodVibesOnlyApartmentPrew034 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-prw/good-vibes-only-apartment-prew-034.jpg';

import goodVibesOnlyApartment001 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-001.jpg';
import goodVibesOnlyApartment002 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-002.jpg';
import goodVibesOnlyApartment003 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-003.jpg';
import goodVibesOnlyApartment004 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-004.jpg';
import goodVibesOnlyApartment005 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-005.jpg';
import goodVibesOnlyApartment006 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-006.jpg';
import goodVibesOnlyApartment007 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-007.jpg';
import goodVibesOnlyApartment008 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-008.jpg';
import goodVibesOnlyApartment009 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-009.jpg';
import goodVibesOnlyApartment010 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-010.jpg';
import goodVibesOnlyApartment011 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-011.jpg';
import goodVibesOnlyApartment012 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-012.jpg';
import goodVibesOnlyApartment013 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-013.jpg';
import goodVibesOnlyApartment014 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-014.jpg';
import goodVibesOnlyApartment015 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-015.jpg';
import goodVibesOnlyApartment016 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-016.jpg';
import goodVibesOnlyApartment017 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-017.jpg';
import goodVibesOnlyApartment018 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-018.jpg';
import goodVibesOnlyApartment019 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-019.jpg';
import goodVibesOnlyApartment020 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-020.jpg';
import goodVibesOnlyApartment021 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-021.jpg';
import goodVibesOnlyApartment022 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-022.jpg';
import goodVibesOnlyApartment023 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-023.jpg';
import goodVibesOnlyApartment024 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-024.jpg';
import goodVibesOnlyApartment025 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-025.jpg';
import goodVibesOnlyApartment026 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-026.jpg';
import goodVibesOnlyApartment027 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-027.jpg';
import goodVibesOnlyApartment028 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-028.jpg';
import goodVibesOnlyApartment029 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-029.jpg';
import goodVibesOnlyApartment030 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-030.jpg';
import goodVibesOnlyApartment031 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-031.jpg';
import goodVibesOnlyApartment032 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-032.jpg';
import goodVibesOnlyApartment033 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-033.jpg';
import goodVibesOnlyApartment034 from '../../img/gallery-apartments/good-vibes-only-apartment/good-vibes-only-apartment-034.jpg';

export const goodVibesOnlyApartment = [
  { key: 'goodVibesOnlyApartment001', image: goodVibesOnlyApartment001, preview: goodVibesOnlyApartmentPrew001, },
  { key: 'goodVibesOnlyApartment002', image: goodVibesOnlyApartment002, preview: goodVibesOnlyApartmentPrew002, },
  { key: 'goodVibesOnlyApartment003', image: goodVibesOnlyApartment003, preview: goodVibesOnlyApartmentPrew003, },
  { key: 'goodVibesOnlyApartment004', image: goodVibesOnlyApartment004, preview: goodVibesOnlyApartmentPrew004, },
  { key: 'goodVibesOnlyApartment005', image: goodVibesOnlyApartment005, preview: goodVibesOnlyApartmentPrew005, },
  { key: 'goodVibesOnlyApartment006', image: goodVibesOnlyApartment006, preview: goodVibesOnlyApartmentPrew006, },
  { key: 'goodVibesOnlyApartment007', image: goodVibesOnlyApartment007, preview: goodVibesOnlyApartmentPrew007, },
  { key: 'goodVibesOnlyApartment008', image: goodVibesOnlyApartment008, preview: goodVibesOnlyApartmentPrew008, },
  { key: 'goodVibesOnlyApartment009', image: goodVibesOnlyApartment009, preview: goodVibesOnlyApartmentPrew009, },
  { key: 'goodVibesOnlyApartment010', image: goodVibesOnlyApartment010, preview: goodVibesOnlyApartmentPrew010, },
  { key: 'goodVibesOnlyApartment011', image: goodVibesOnlyApartment011, preview: goodVibesOnlyApartmentPrew011, },
  { key: 'goodVibesOnlyApartment012', image: goodVibesOnlyApartment012, preview: goodVibesOnlyApartmentPrew012, },
  { key: 'goodVibesOnlyApartment013', image: goodVibesOnlyApartment013, preview: goodVibesOnlyApartmentPrew013, },
  { key: 'goodVibesOnlyApartment014', image: goodVibesOnlyApartment014, preview: goodVibesOnlyApartmentPrew014, },
  { key: 'goodVibesOnlyApartment015', image: goodVibesOnlyApartment015, preview: goodVibesOnlyApartmentPrew015, },
  { key: 'goodVibesOnlyApartment016', image: goodVibesOnlyApartment016, preview: goodVibesOnlyApartmentPrew016, },
  { key: 'goodVibesOnlyApartment017', image: goodVibesOnlyApartment017, preview: goodVibesOnlyApartmentPrew017, },
  { key: 'goodVibesOnlyApartment018', image: goodVibesOnlyApartment018, preview: goodVibesOnlyApartmentPrew018, },
  { key: 'goodVibesOnlyApartment019', image: goodVibesOnlyApartment019, preview: goodVibesOnlyApartmentPrew019, },
  { key: 'goodVibesOnlyApartment020', image: goodVibesOnlyApartment020, preview: goodVibesOnlyApartmentPrew020, },
  { key: 'goodVibesOnlyApartment021', image: goodVibesOnlyApartment021, preview: goodVibesOnlyApartmentPrew021, },
  { key: 'goodVibesOnlyApartment022', image: goodVibesOnlyApartment022, preview: goodVibesOnlyApartmentPrew022, },
  { key: 'goodVibesOnlyApartment023', image: goodVibesOnlyApartment023, preview: goodVibesOnlyApartmentPrew023, },
  { key: 'goodVibesOnlyApartment024', image: goodVibesOnlyApartment024, preview: goodVibesOnlyApartmentPrew024, },
  { key: 'goodVibesOnlyApartment025', image: goodVibesOnlyApartment025, preview: goodVibesOnlyApartmentPrew025, },
  { key: 'goodVibesOnlyApartment026', image: goodVibesOnlyApartment026, preview: goodVibesOnlyApartmentPrew026, },
  { key: 'goodVibesOnlyApartment027', image: goodVibesOnlyApartment027, preview: goodVibesOnlyApartmentPrew027, },
  { key: 'goodVibesOnlyApartment028', image: goodVibesOnlyApartment028, preview: goodVibesOnlyApartmentPrew028, },
  { key: 'goodVibesOnlyApartment029', image: goodVibesOnlyApartment029, preview: goodVibesOnlyApartmentPrew029, },
  { key: 'goodVibesOnlyApartment030', image: goodVibesOnlyApartment030, preview: goodVibesOnlyApartmentPrew030, },
  { key: 'goodVibesOnlyApartment031', image: goodVibesOnlyApartment031, preview: goodVibesOnlyApartmentPrew031, },
  { key: 'goodVibesOnlyApartment032', image: goodVibesOnlyApartment032, preview: goodVibesOnlyApartmentPrew032, },
  { key: 'goodVibesOnlyApartment033', image: goodVibesOnlyApartment033, preview: goodVibesOnlyApartmentPrew033, },
  { key: 'goodVibesOnlyApartment034', image: goodVibesOnlyApartment034, preview: goodVibesOnlyApartmentPrew034, },


]