import floraApartment001 from '../../img/gallery-apartments/flora-apartment/flora-apartment-001.jpg';
import floraApartment002 from '../../img/gallery-apartments/flora-apartment/flora-apartment-002.jpg';
import floraApartment003 from '../../img/gallery-apartments/flora-apartment/flora-apartment-003.jpg';
import floraApartment004 from '../../img/gallery-apartments/flora-apartment/flora-apartment-004.jpg';
import floraApartment005 from '../../img/gallery-apartments/flora-apartment/flora-apartment-005.jpg';
import floraApartment006 from '../../img/gallery-apartments/flora-apartment/flora-apartment-006.jpg';
import floraApartment007 from '../../img/gallery-apartments/flora-apartment/flora-apartment-007.jpg';
import floraApartment008 from '../../img/gallery-apartments/flora-apartment/flora-apartment-008.jpg';
import floraApartment009 from '../../img/gallery-apartments/flora-apartment/flora-apartment-009.jpg';
import floraApartment010 from '../../img/gallery-apartments/flora-apartment/flora-apartment-010.jpg';
import floraApartment011 from '../../img/gallery-apartments/flora-apartment/flora-apartment-011.jpg';
import floraApartment012 from '../../img/gallery-apartments/flora-apartment/flora-apartment-012.jpg';
import floraApartment013 from '../../img/gallery-apartments/flora-apartment/flora-apartment-013.jpg';
import floraApartment014 from '../../img/gallery-apartments/flora-apartment/flora-apartment-014.jpg';
import floraApartment015 from '../../img/gallery-apartments/flora-apartment/flora-apartment-015.jpg';
import floraApartment016 from '../../img/gallery-apartments/flora-apartment/flora-apartment-016.jpg';
import floraApartment017 from '../../img/gallery-apartments/flora-apartment/flora-apartment-017.jpg';
import floraApartment018 from '../../img/gallery-apartments/flora-apartment/flora-apartment-018.jpg';
import floraApartment019 from '../../img/gallery-apartments/flora-apartment/flora-apartment-019.jpg';
import floraApartment020 from '../../img/gallery-apartments/flora-apartment/flora-apartment-020.jpg';
import floraApartment021 from '../../img/gallery-apartments/flora-apartment/flora-apartment-021.jpg';
import floraApartment022 from '../../img/gallery-apartments/flora-apartment/flora-apartment-022.jpg';
import floraApartment023 from '../../img/gallery-apartments/flora-apartment/flora-apartment-023.jpg';
import floraApartment024 from '../../img/gallery-apartments/flora-apartment/flora-apartment-024.jpg';
import floraApartment025 from '../../img/gallery-apartments/flora-apartment/flora-apartment-025.jpg';
import floraApartment026 from '../../img/gallery-apartments/flora-apartment/flora-apartment-026.jpg';
import floraApartment027 from '../../img/gallery-apartments/flora-apartment/flora-apartment-027.jpg';
import floraApartment028 from '../../img/gallery-apartments/flora-apartment/flora-apartment-028.jpg';
import floraApartment029 from '../../img/gallery-apartments/flora-apartment/flora-apartment-029.jpg';
import floraApartment030 from '../../img/gallery-apartments/flora-apartment/flora-apartment-030.jpg';
import floraApartment031 from '../../img/gallery-apartments/flora-apartment/flora-apartment-031.jpg';
import floraApartment032 from '../../img/gallery-apartments/flora-apartment/flora-apartment-032.jpg';
import floraApartment033 from '../../img/gallery-apartments/flora-apartment/flora-apartment-033.jpg';
import floraApartment034 from '../../img/gallery-apartments/flora-apartment/flora-apartment-034.jpg';

import floraApartmentPrew001 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-001.jpg';
import floraApartmentPrew002 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-002.jpg';
import floraApartmentPrew003 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-003.jpg';
import floraApartmentPrew004 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-004.jpg';
import floraApartmentPrew005 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-005.jpg';
import floraApartmentPrew006 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-006.jpg';
import floraApartmentPrew007 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-007.jpg';
import floraApartmentPrew008 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-008.jpg';
import floraApartmentPrew009 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-009.jpg';
import floraApartmentPrew010 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-010.jpg';
import floraApartmentPrew011 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-011.jpg';
import floraApartmentPrew012 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-012.jpg';
import floraApartmentPrew013 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-013.jpg';
import floraApartmentPrew014 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-014.jpg';
import floraApartmentPrew015 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-015.jpg';
import floraApartmentPrew016 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-016.jpg';
import floraApartmentPrew017 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-017.jpg';
import floraApartmentPrew018 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-018.jpg';
import floraApartmentPrew019 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-019.jpg';
import floraApartmentPrew020 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-020.jpg';
import floraApartmentPrew021 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-021.jpg';
import floraApartmentPrew022 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-022.jpg';
import floraApartmentPrew023 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-023.jpg';
import floraApartmentPrew024 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-024.jpg';
import floraApartmentPrew025 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-025.jpg';
import floraApartmentPrew026 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-026.jpg';
import floraApartmentPrew027 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-027.jpg';
import floraApartmentPrew028 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-028.jpg';
import floraApartmentPrew029 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-029.jpg';
import floraApartmentPrew030 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-030.jpg';
import floraApartmentPrew031 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-031.jpg';
import floraApartmentPrew032 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-032.jpg';
import floraApartmentPrew033 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-033.jpg';
import floraApartmentPrew034 from '../../img/gallery-apartments/flora-apartment/flora-apartment-prw/flora-apartment-prew-034.jpg';

export const floraApartment = [
  { key: 'floraApartment001', image: floraApartment001, preview: floraApartmentPrew001, },
  { key: 'floraApartment002', image: floraApartment002, preview: floraApartmentPrew002, },
  { key: 'floraApartment003', image: floraApartment003, preview: floraApartmentPrew003, },
  { key: 'floraApartment004', image: floraApartment004, preview: floraApartmentPrew004, },
  { key: 'floraApartment005', image: floraApartment005, preview: floraApartmentPrew005, },
  { key: 'floraApartment006', image: floraApartment006, preview: floraApartmentPrew006, },
  { key: 'floraApartment007', image: floraApartment007, preview: floraApartmentPrew007, },
  { key: 'floraApartment008', image: floraApartment008, preview: floraApartmentPrew008, },
  { key: 'floraApartment009', image: floraApartment009, preview: floraApartmentPrew009, },
  { key: 'floraApartment010', image: floraApartment010, preview: floraApartmentPrew010, },
  { key: 'floraApartment011', image: floraApartment011, preview: floraApartmentPrew011, },
  { key: 'floraApartment012', image: floraApartment012, preview: floraApartmentPrew012, },
  { key: 'floraApartment013', image: floraApartment013, preview: floraApartmentPrew013, },
  { key: 'floraApartment014', image: floraApartment014, preview: floraApartmentPrew014, },
  { key: 'floraApartment015', image: floraApartment015, preview: floraApartmentPrew015, },
  { key: 'floraApartment016', image: floraApartment016, preview: floraApartmentPrew016, },
  { key: 'floraApartment017', image: floraApartment017, preview: floraApartmentPrew017, },
  { key: 'floraApartment018', image: floraApartment018, preview: floraApartmentPrew018, },
  { key: 'floraApartment019', image: floraApartment019, preview: floraApartmentPrew019, },
  { key: 'floraApartment020', image: floraApartment020, preview: floraApartmentPrew020, },
  { key: 'floraApartment021', image: floraApartment021, preview: floraApartmentPrew021, },
  { key: 'floraApartment022', image: floraApartment022, preview: floraApartmentPrew022, },
  { key: 'floraApartment023', image: floraApartment023, preview: floraApartmentPrew023, },
  { key: 'floraApartment024', image: floraApartment024, preview: floraApartmentPrew024, },
  { key: 'floraApartment025', image: floraApartment025, preview: floraApartmentPrew025, },
  { key: 'floraApartment026', image: floraApartment026, preview: floraApartmentPrew026, },
  { key: 'floraApartment027', image: floraApartment027, preview: floraApartmentPrew027, },
  { key: 'floraApartment028', image: floraApartment028, preview: floraApartmentPrew028, },
  { key: 'floraApartment029', image: floraApartment029, preview: floraApartmentPrew029, },
  { key: 'floraApartment030', image: floraApartment030, preview: floraApartmentPrew030, },
  { key: 'floraApartment031', image: floraApartment031, preview: floraApartmentPrew031, },
  { key: 'floraApartment032', image: floraApartment032, preview: floraApartmentPrew032, },
  { key: 'floraApartment033', image: floraApartment033, preview: floraApartmentPrew033, },
  { key: 'floraApartment034', image: floraApartment034, preview: floraApartmentPrew034, },
];
