import centralStreetApartment001 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-001.jpg';
import centralStreetApartment002 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-002.jpg';
import centralStreetApartment003 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-003.jpg';
import centralStreetApartment004 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-004.jpg';
import centralStreetApartment005 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-005.jpg';
import centralStreetApartment006 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-006.jpg';
import centralStreetApartment007 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-007.jpg';
import centralStreetApartment008 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-008.jpg';
import centralStreetApartment009 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-009.jpg';
import centralStreetApartment010 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-010.jpg';
import centralStreetApartment011 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-011.jpg';
import centralStreetApartment012 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-012.jpg';
import centralStreetApartment013 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-013.jpg';
import centralStreetApartment014 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-014.jpg';
import centralStreetApartment015 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-015.jpg';
import centralStreetApartment016 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-016.jpg';
import centralStreetApartment017 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-017.jpg';
import centralStreetApartment018 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-018.jpg';
import centralStreetApartment019 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-019.jpg';
import centralStreetApartment020 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-020.jpg';
import centralStreetApartment021 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-021.jpg';
import centralStreetApartment022 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-022.jpg';
import centralStreetApartment023 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-023.jpg';
import centralStreetApartment024 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-024.jpg';
import centralStreetApartment025 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-025.jpg';
import centralStreetApartment026 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-026.jpg';
import centralStreetApartment027 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-027.jpg';
import centralStreetApartment028 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-028.jpg';
import centralStreetApartment029 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-029.jpg';
import centralStreetApartment030 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-030.jpg';
import centralStreetApartment031 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-031.jpg';
import centralStreetApartment032 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-032.jpg';
import centralStreetApartment033 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-033.jpg';
import centralStreetApartment034 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-034.jpg';
import centralStreetApartment035 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-035.jpg';

import centralStreetApartmentPrew001 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-001.jpg';
import centralStreetApartmentPrew002 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-002.jpg';
import centralStreetApartmentPrew003 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-003.jpg';
import centralStreetApartmentPrew004 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-004.jpg';
import centralStreetApartmentPrew005 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-005.jpg';
import centralStreetApartmentPrew006 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-006.jpg';
import centralStreetApartmentPrew007 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-007.jpg';
import centralStreetApartmentPrew008 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-008.jpg';
import centralStreetApartmentPrew009 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-009.jpg';
import centralStreetApartmentPrew010 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-010.jpg';
import centralStreetApartmentPrew011 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-011.jpg';
import centralStreetApartmentPrew012 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-012.jpg';
import centralStreetApartmentPrew013 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-013.jpg';
import centralStreetApartmentPrew014 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-014.jpg';
import centralStreetApartmentPrew015 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-015.jpg';
import centralStreetApartmentPrew016 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-016.jpg';
import centralStreetApartmentPrew017 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-017.jpg';
import centralStreetApartmentPrew018 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-018.jpg';
import centralStreetApartmentPrew019 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-019.jpg';
import centralStreetApartmentPrew020 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-020.jpg';
import centralStreetApartmentPrew021 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-021.jpg';
import centralStreetApartmentPrew022 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-022.jpg';
import centralStreetApartmentPrew023 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-023.jpg';
import centralStreetApartmentPrew024 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-024.jpg';
import centralStreetApartmentPrew025 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-025.jpg';
import centralStreetApartmentPrew026 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-026.jpg';
import centralStreetApartmentPrew027 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-027.jpg';
import centralStreetApartmentPrew028 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-028.jpg';
import centralStreetApartmentPrew029 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-029.jpg';
import centralStreetApartmentPrew030 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-030.jpg';
import centralStreetApartmentPrew031 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-031.jpg';
import centralStreetApartmentPrew032 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-032.jpg';
import centralStreetApartmentPrew033 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-033.jpg';
import centralStreetApartmentPrew034 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-034.jpg';
import centralStreetApartmentPrew035 from '../../img/gallery-apartments/central-street-apartment/central-street-apartment-prw/central-street-apartment-prew-035.jpg';


export const centralStreetApartment = [
  { key: 'centralStreetApartment001', image: centralStreetApartment001, preview: centralStreetApartmentPrew001, },
  { key: 'centralStreetApartment002', image: centralStreetApartment002, preview: centralStreetApartmentPrew002, },
  { key: 'centralStreetApartment003', image: centralStreetApartment003, preview: centralStreetApartmentPrew003, },
  { key: 'centralStreetApartment004', image: centralStreetApartment004, preview: centralStreetApartmentPrew004, },
  { key: 'centralStreetApartment005', image: centralStreetApartment005, preview: centralStreetApartmentPrew005, },
  { key: 'centralStreetApartment006', image: centralStreetApartment006, preview: centralStreetApartmentPrew006, },
  { key: 'centralStreetApartment007', image: centralStreetApartment007, preview: centralStreetApartmentPrew007, },
  { key: 'centralStreetApartment008', image: centralStreetApartment008, preview: centralStreetApartmentPrew008, },
  { key: 'centralStreetApartment009', image: centralStreetApartment009, preview: centralStreetApartmentPrew009, },
  { key: 'centralStreetApartment010', image: centralStreetApartment010, preview: centralStreetApartmentPrew010, },
  { key: 'centralStreetApartment011', image: centralStreetApartment011, preview: centralStreetApartmentPrew011, },
  { key: 'centralStreetApartment012', image: centralStreetApartment012, preview: centralStreetApartmentPrew012, },
  { key: 'centralStreetApartment013', image: centralStreetApartment013, preview: centralStreetApartmentPrew013, },
  { key: 'centralStreetApartment014', image: centralStreetApartment014, preview: centralStreetApartmentPrew014, },
  { key: 'centralStreetApartment015', image: centralStreetApartment015, preview: centralStreetApartmentPrew015, },
  { key: 'centralStreetApartment016', image: centralStreetApartment016, preview: centralStreetApartmentPrew016, },
  { key: 'centralStreetApartment017', image: centralStreetApartment017, preview: centralStreetApartmentPrew017, },
  { key: 'centralStreetApartment018', image: centralStreetApartment018, preview: centralStreetApartmentPrew018, },
  { key: 'centralStreetApartment019', image: centralStreetApartment019, preview: centralStreetApartmentPrew019, },
  { key: 'centralStreetApartment020', image: centralStreetApartment020, preview: centralStreetApartmentPrew020, },
  { key: 'centralStreetApartment021', image: centralStreetApartment021, preview: centralStreetApartmentPrew021, },
  { key: 'centralStreetApartment022', image: centralStreetApartment022, preview: centralStreetApartmentPrew022, },
  { key: 'centralStreetApartment023', image: centralStreetApartment023, preview: centralStreetApartmentPrew023, },
  { key: 'centralStreetApartment024', image: centralStreetApartment024, preview: centralStreetApartmentPrew024, },
  { key: 'centralStreetApartment025', image: centralStreetApartment025, preview: centralStreetApartmentPrew025, },
  { key: 'centralStreetApartment026', image: centralStreetApartment026, preview: centralStreetApartmentPrew026, },
  { key: 'centralStreetApartment027', image: centralStreetApartment027, preview: centralStreetApartmentPrew027, },
  { key: 'centralStreetApartment028', image: centralStreetApartment028, preview: centralStreetApartmentPrew028, },
  { key: 'centralStreetApartment029', image: centralStreetApartment029, preview: centralStreetApartmentPrew029, },
  { key: 'centralStreetApartment030', image: centralStreetApartment030, preview: centralStreetApartmentPrew030, },
  { key: 'centralStreetApartment031', image: centralStreetApartment031, preview: centralStreetApartmentPrew031, },
  { key: 'centralStreetApartment032', image: centralStreetApartment032, preview: centralStreetApartmentPrew032, },
  { key: 'centralStreetApartment033', image: centralStreetApartment033, preview: centralStreetApartmentPrew033, },
  { key: 'centralStreetApartment034', image: centralStreetApartment034, preview: centralStreetApartmentPrew034, },
  { key: 'centralStreetApartment035', image: centralStreetApartment035, preview: centralStreetApartmentPrew035, },
]