import greenApartmentCityView001 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-001.jpg';
import greenApartmentCityView002 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-002.jpg';
import greenApartmentCityView003 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-003.jpg';
import greenApartmentCityView004 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-004.jpg';
import greenApartmentCityView005 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-005.jpg';
import greenApartmentCityView006 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-006.jpg';
import greenApartmentCityView007 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-007.jpg';
import greenApartmentCityView008 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-008.jpg';
import greenApartmentCityView009 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-009.jpg';
import greenApartmentCityView010 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-010.jpg';
import greenApartmentCityView011 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-011.jpg';
import greenApartmentCityView012 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-012.jpg';
import greenApartmentCityView013 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-013.jpg';
import greenApartmentCityView014 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-014.jpg';
import greenApartmentCityView015 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-015.jpg';
import greenApartmentCityView016 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-016.jpg';
import greenApartmentCityView017 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-017.jpg';
import greenApartmentCityView018 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-018.jpg';
import greenApartmentCityView019 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-019.jpg';
import greenApartmentCityView020 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-020.jpg';
import greenApartmentCityView021 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-021.jpg';
import greenApartmentCityView022 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-022.jpg';
import greenApartmentCityView023 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-023.jpg';
import greenApartmentCityView024 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-024.jpg';
import greenApartmentCityView025 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-025.jpg';
import greenApartmentCityView026 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-026.jpg';
import greenApartmentCityView027 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-027.jpg';
import greenApartmentCityView028 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-028.jpg';
import greenApartmentCityView029 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-029.jpg';
import greenApartmentCityView030 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-030.jpg';
import greenApartmentCityView031 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-031.jpg';
import greenApartmentCityView032 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-032.jpg';

import greenApartmentCityViewPrew001 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-001.jpg';
import greenApartmentCityViewPrew002 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-002.jpg';
import greenApartmentCityViewPrew003 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-003.jpg';
import greenApartmentCityViewPrew004 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-004.jpg';
import greenApartmentCityViewPrew005 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-005.jpg';
import greenApartmentCityViewPrew006 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-006.jpg';
import greenApartmentCityViewPrew007 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-007.jpg';
import greenApartmentCityViewPrew008 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-008.jpg';
import greenApartmentCityViewPrew009 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-009.jpg';
import greenApartmentCityViewPrew010 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-010.jpg';
import greenApartmentCityViewPrew011 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-011.jpg';
import greenApartmentCityViewPrew012 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-012.jpg';
import greenApartmentCityViewPrew013 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-013.jpg';
import greenApartmentCityViewPrew014 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-014.jpg';
import greenApartmentCityViewPrew015 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-015.jpg';
import greenApartmentCityViewPrew016 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-016.jpg';
import greenApartmentCityViewPrew017 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-017.jpg';
import greenApartmentCityViewPrew018 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-018.jpg';
import greenApartmentCityViewPrew019 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-019.jpg';
import greenApartmentCityViewPrew020 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-020.jpg';
import greenApartmentCityViewPrew021 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-021.jpg';
import greenApartmentCityViewPrew022 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-022.jpg';
import greenApartmentCityViewPrew023 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-023.jpg';
import greenApartmentCityViewPrew024 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-024.jpg';
import greenApartmentCityViewPrew025 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-025.jpg';
import greenApartmentCityViewPrew026 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-026.jpg';
import greenApartmentCityViewPrew027 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-027.jpg';
import greenApartmentCityViewPrew028 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-028.jpg';
import greenApartmentCityViewPrew029 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-029.jpg';
import greenApartmentCityViewPrew030 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-030.jpg';
import greenApartmentCityViewPrew031 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-031.jpg';
import greenApartmentCityViewPrew032 from '../../img/gallery-apartments/green-apartment-city-view/green-apartment-city-view-prw/green-apartment-city-view-prew-032.jpg';

export const greenApartmentCityView = [
  { key: 'greenApartmentCityView001', image: greenApartmentCityView001, preview: greenApartmentCityViewPrew001, },
  { key: 'greenApartmentCityView002', image: greenApartmentCityView002, preview: greenApartmentCityViewPrew002, },
  { key: 'greenApartmentCityView003', image: greenApartmentCityView003, preview: greenApartmentCityViewPrew003, },
  { key: 'greenApartmentCityView004', image: greenApartmentCityView004, preview: greenApartmentCityViewPrew004, },
  { key: 'greenApartmentCityView005', image: greenApartmentCityView005, preview: greenApartmentCityViewPrew005, },
  { key: 'greenApartmentCityView006', image: greenApartmentCityView006, preview: greenApartmentCityViewPrew006, },
  { key: 'greenApartmentCityView007', image: greenApartmentCityView007, preview: greenApartmentCityViewPrew007, },
  { key: 'greenApartmentCityView008', image: greenApartmentCityView008, preview: greenApartmentCityViewPrew008, },
  { key: 'greenApartmentCityView009', image: greenApartmentCityView009, preview: greenApartmentCityViewPrew009, },
  { key: 'greenApartmentCityView010', image: greenApartmentCityView010, preview: greenApartmentCityViewPrew010, },
  { key: 'greenApartmentCityView011', image: greenApartmentCityView011, preview: greenApartmentCityViewPrew011, },
  { key: 'greenApartmentCityView012', image: greenApartmentCityView012, preview: greenApartmentCityViewPrew012, },
  { key: 'greenApartmentCityView013', image: greenApartmentCityView013, preview: greenApartmentCityViewPrew013, },
  { key: 'greenApartmentCityView014', image: greenApartmentCityView014, preview: greenApartmentCityViewPrew014, },
  { key: 'greenApartmentCityView015', image: greenApartmentCityView015, preview: greenApartmentCityViewPrew015, },
  { key: 'greenApartmentCityView016', image: greenApartmentCityView016, preview: greenApartmentCityViewPrew016, },
  { key: 'greenApartmentCityView017', image: greenApartmentCityView017, preview: greenApartmentCityViewPrew017, },
  { key: 'greenApartmentCityView018', image: greenApartmentCityView018, preview: greenApartmentCityViewPrew018, },
  { key: 'greenApartmentCityView019', image: greenApartmentCityView019, preview: greenApartmentCityViewPrew019, },
  { key: 'greenApartmentCityView020', image: greenApartmentCityView020, preview: greenApartmentCityViewPrew020, },
  { key: 'greenApartmentCityView021', image: greenApartmentCityView021, preview: greenApartmentCityViewPrew021, },
  { key: 'greenApartmentCityView022', image: greenApartmentCityView022, preview: greenApartmentCityViewPrew022, },
  { key: 'greenApartmentCityView023', image: greenApartmentCityView023, preview: greenApartmentCityViewPrew023, },
  { key: 'greenApartmentCityView024', image: greenApartmentCityView024, preview: greenApartmentCityViewPrew024, },
  { key: 'greenApartmentCityView025', image: greenApartmentCityView025, preview: greenApartmentCityViewPrew025, },
  { key: 'greenApartmentCityView026', image: greenApartmentCityView026, preview: greenApartmentCityViewPrew026, },
  { key: 'greenApartmentCityView027', image: greenApartmentCityView027, preview: greenApartmentCityViewPrew027, },
  { key: 'greenApartmentCityView028', image: greenApartmentCityView028, preview: greenApartmentCityViewPrew028, },
  { key: 'greenApartmentCityView029', image: greenApartmentCityView029, preview: greenApartmentCityViewPrew029, },
  { key: 'greenApartmentCityView030', image: greenApartmentCityView030, preview: greenApartmentCityViewPrew030, },
  { key: 'greenApartmentCityView031', image: greenApartmentCityView031, preview: greenApartmentCityViewPrew031, },
  { key: 'greenApartmentCityView032', image: greenApartmentCityView032, preview: greenApartmentCityViewPrew032, },
];