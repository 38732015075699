import { sourcePersons } from '../sourcePersons';

export const marshmallowStudioRecommendations = [
  {
    key: 'marshmallowStudioRecommendations001', image: sourcePersons,
    text: 'Мой отзыв сугубо положительный! Нам была важна именно возможность позднего заселения, здесь, благодаря кодовому замку это стало возможно. И хозяев квартиры не надо дергать в ночи. Удобно, что ключи не нужно носить с собой. И припарковаться ночью мы тоже смогли!)) На выходных с этим было легче) Очень уютная, светлая, комфортная квартира. Хоть и небольшая, но в ней есть все, для комфортного отдыха. Полностью соответствует фотографиям. Очень приятны такие мелочи как тапочки, гигиенические штуки, чай-кофе, сладости. Есть стиральный порошок, если вдруг нужно затеять стирку. Все сделано с заботой к постояльцам, чтобы они (мы) чувствовали себя как дома. Огромное вам спасибо за это!',
  },
  {
    key: 'marshmallowStudioRecommendations002', image: sourcePersons,
    text: 'все было чудесно,даже не храпел))спасибо хозяйке за хороший приём.',
  },
  {
    key: 'marshmallowStudioRecommendations003', image: sourcePersons,
    text: 'Уютно, чисто и тепло !',
  },
  {
    key: 'marshmallowStudioRecommendations004', image: sourcePersons,
    text: 'Огромное спасибо Екатерине, за тёплый прием. Отличные условия для проживания. Комфорт уют и чистота, то что нужно любому человеку',
  },
  {
    key: 'marshmallowStudioRecommendations005', image: sourcePersons,
    text: 'Всё гуд',
  },
  {
    key: 'marshmallowStudioRecommendations006', image: sourcePersons,
    text: 'хорошая чистая уютная квартирка) советую!',
  },
  {
    key: 'marshmallowStudioRecommendations007', image: sourcePersons,
    text: 'Очень стильная, чистая, уютная квартира! Фотографии полностью соответствуют реальности. Есть всё необходимое, очень приятно, что присутствуют всякие мелочи по типу аптечки, сладостей и минимальных продуктов! Находиться там было максимально комфортно. Екатерина отвечает очень быстро и дружелюбно)',
  },
];