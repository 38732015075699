import React from 'react';

const VideoPresentation = () => {
  return (
    <main className='main'>
      VideoPresentation
    </main>
  );
}

export default VideoPresentation;
