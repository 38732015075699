import { sourcePersons } from '../sourcePersons';

export const linerRecommendations = [
  {
    key: 'linerRecommendations001', image: sourcePersons,
    text: 'Квартира в новом доме со свежим ремонтом, в 15 минутах на машине от центра. Фото соответсвуют квартире. Есть все необходимое для кратковременного пребывания. Рядом гипер "Лента". Заселение самостоятельное, что удобно.',
  },
  {
    key: 'linerRecommendations002', image: sourcePersons,
    text: 'Квартира в новом элитном ЖК, от центра 15 минут на машине. Квартира на вашем уровне! Новый, качественный дизайнерский ремонт, очень стильно и красиво! Одно из главных достоинств - чисто идеально! Чистое кипельно белое белье и полотенце! Захочешь придраться, а не к чему) Если что, мы ездим по городам России не первый год и в Нижнем были не раз и я к тому же педант жуткий!) Качественная, совсем не дешевая бытовая техника. Есть балкон с панорамными окнами. Вид с балкона и из окна - завораживающий! Ещё один показатель профессионального и качественного подхода - наличие нужных и приятных мелочей: ватнве палочки, диски, прокладки, душевые принадлежности, швейный набор, чемоданчик "первой помощи", сладкие сюрпризы, чай, кофе в пакетиках. Очень приятно, что есть еще такие арендодателя! Если в Нижний - только в эту квартиру!',
  },
  {
    key: 'linerRecommendations003', image: sourcePersons,
    text: 'Первый раз снимал жильё через приложения. Спасибо Екатерине, она все подробно объяснила, подобрала свободную квартиру, заселила очень оперативно.',
  },
  {
    key: 'linerRecommendations004', image: sourcePersons,
    text: 'Уютная, новая, стильная квартира. Есть все необходимое - техника, посуда. Вид на реку прекрасный. Кондиционер спасал в жару. Квартиру рекомендую. Отдельное спасибо от детей за сладкий презент)))',
  },
  {
    key: 'linerRecommendations005', image: sourcePersons,
    text: 'Квартира шикарная, заселение прошло без проблем. На кухне ждали вкусняшки🙂 Много мест на стоянке, рядом Лента в шаговой доступности',
  },
  {
    key: 'linerRecommendations006', image: sourcePersons,
    text: 'Мы очень благодарны Екатерине за наше проживание. Квартира в новом доме. Еще мало кто, живет. Шумных работ не велось. В квартире дизайнерский ремонт. Все продумано до мелочей, очень много посуды. Стиральный порошок, чай, кофе, сладости, крупы. Есть все, что нужно и даже больше!!! Обязательно вернёмся еще. Спасибо!!!',
  },
  {
    key: 'linerRecommendations007', image: sourcePersons,
    text: 'Хорошая ЧИСТАЯ , новая квартира. Есть все что нужно и даже чуть больше, для комфортного проживания.',
  },
  {
    key: 'linerRecommendations008', image: sourcePersons,
    text: 'Заселись легко, электронный замок. Светлая чистая квартира в новом доме, дизайнерский ремонт. Отличный вид из окна. Есть вся необходимая техника и посуда. Соседей вокруг практически нет. Разве что делают ремонт и то я практически никого не слышал.',
  },
  {
    key: 'linerRecommendations009', image: sourcePersons,
    text: 'Шикарная квартира, все понравилось!',
  },
];