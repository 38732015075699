import { sourcePersons } from '../sourcePersons';

export const artPоintApartmentRecommendations = [
  {
    key: 'artPоintApartmentRecommendations001', image: sourcePersons,
    text: 'Всё понравилось спасибо,рекомендую!!!',
  },
  {
    key: 'artPоintApartmentRecommendations002', image: sourcePersons,
    text: 'Чудесная квартира,все супер',
  },
  {
    key: 'artPоintApartmentRecommendations003', image: sourcePersons,
    text: 'Выбрали квартиру по дизайну и расположению. Все соответствует фото, наполнение приятно удивило все от чая-кофе (молотый тоже есть) до ватных дисков и одноразовых тапочек. Не ожидала увидеть крупу, но и она была, воспользовались гречкой ребенку на завтрак. Находиться приятно, чистота не вызывает сомнений. Хочется пожелать владельцам процветания и адекватных арендаторов, а мы при возможности приедем еще 🧡',
  },
  {
    key: 'artPоintApartmentRecommendations004', image: sourcePersons,
    text: 'Уютная квартира, с дизайнерским ремонтом. Идеальная чистота!Есть все необходимое для проживания, даже в мелочах чувствуется забота о гостях. Во дворе парковка для машин. Все магазины рядом. Бесконтактное заселение, если есть вопросы всегда на связи. Екатерине спасибо за гостеприимство!',
  },
  {
    key: 'artPоintApartmentRecommendations005', image: sourcePersons,
    text: 'Все отлично! Квартира чистая! Остался доволен. Тем кто боится бронировать квартиру, спешу обрадовать, что ее можно бронировать и не переживать!',
  },
  {
    key: 'artPоintApartmentRecommendations006', image: sourcePersons,
    text: 'шикарная квартира с потрясающим дизайном!!! в ней хочется жить, творить и любить!',
  },
  {
    key: 'artPоintApartmentRecommendations007', image: sourcePersons,
    text: 'Чистая, обуродованная всем необходимым квартира. Обязательно приедем ещё.',
  },
  {
    key: 'artPоintApartmentRecommendations008', image: sourcePersons,
    text: 'Прекрасная, очень красивая и уютная квартира. В квартире есть абсолютно все, что сожет понадобится. Екатерина очень быстро отвечала на все вопросы. Остались очень довольны',
  },
  {
    key: 'artPоintApartmentRecommendations009', image: sourcePersons,
    text: 'Чистота',
  },
  {
    key: 'artPоintApartmentRecommendations010', image: sourcePersons,
    text: 'Чудесная квартира! Все как на картинках, новое и чистое. Отвечали на все сообщения быстро. Очень приятно, что арендодатели с такой заботой относятся к своим посетителям. В квартире были одноразовые тапочки, зубной набор, чай, кофе, твикс, шампуни, мыло и многое другое. 5 звезд одним словом!',
  },
  {
    key: 'artPоintApartmentRecommendations011', image: sourcePersons,
    text: 'Идеальная квартира и вежливый арендодатель, обязательно вернусь ещё 👍',
  },
  {
    key: 'artPоintApartmentRecommendations012', image: sourcePersons,
    text: 'Остались исключительно положительные впечатления! Квартира в недавно отстроенном ЖК, новая, красивая, уютная, с отличным ремонтом, оборудована всей техникой и предусмотрена куча мелочей - о чем не подумаешь, все есть) Общаться исключительно приятно- все четко, доброжелательно, оперативно. Рекомендую на все 100%)',
  },

];