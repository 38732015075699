import { sourcePersons } from '../sourcePersons';

export const centralStreetApartmentRecommendations = [
  {
    key: 'centralStreetApartmentRecommendations001', image: sourcePersons,
    text: 'Отличная квартира. Есть все, что нужно - шапмунь, гель для душа, зубная паста, щетки, стиральеый порошек. Расположение очень удобное, рядом много кафе. Есть парковка, территория закрыта. Собственник всегла на связи',
  },
  {
    key: 'centralStreetApartmentRecommendations002', image: sourcePersons,
    text: 'Жила не я, но по словам мужа квартира просто вау, все оборудовано для комфортного проживания, все чистое, новое! очень приятны были индивидуальные наборы для личной гигиены! Расположение супер, с парковкой проблем нет! ответили на все вопросы и пожелания! Огромное спасибо и удачи в Вашем бизнесе! Однозначно будем рекомендовать друзьям!',
  },
  {
    key: 'centralStreetApartmentRecommendations003', image: sourcePersons,
    text: 'отличная уютная новая квартира с хорошей меблировкой. парковка во дворе. 2 шага до Покровки. 5 минут пешком до Кремля. отлично отдохнули. Заселение бесконтактное, очень удобно и быстро. на все вопросы получали оперативно ответы. Рекомендую.',
  },
  {
    key: 'centralStreetApartmentRecommendations004', image: sourcePersons,
    text: 'Очень понравилась квартира🤩 Местоположение, уютная, все удобства😍',
  },

];