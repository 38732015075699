import greenApartmentCityViewSlide from '../img/slider-main/green-apartment-city-view.webp';
import marshmallowStudioSlide from '../img/slider-main/marshmallow-studio.webp';
import linerSlide from '../img/slider-main/liner.webp';
import floraApartmentSlide from '../img/slider-main/flora-apartment.webp';
import goodVibesOnlyApartmentSlide from '../img/slider-main/good-vibes-only-apartment.webp';
import centralStreetApartmentSlide from '../img/slider-main/central-street-apartment.webp';
import muzaApаrtmentSlide from '../img/slider-main/muza-аpаrtment.webp';
import artAоintApartmentSlide from '../img/slider-main/art-роint-араrtmеnt.webp';
import fаmilyApаrtmentOсeаnisSlide from '../img/slider-main/fаmily-аpаrtment-oсeаnis.webp';
import eleganceApartmentSlide from '../img/slider-main/elegance-apartment.webp';

import { greenApartmentCityView } from "./apartSourceImg/green-apartment-city-view";
import { marshmallowStudio } from "./apartSourceImg/marshmallow-studio";
import { liner } from "./apartSourceImg/liner.js";
import { floraApartment } from "./apartSourceImg/floraApartment";
import { goodVibesOnlyApartment } from "./apartSourceImg/good-vibes-only-apartment";
import { centralStreetApartment } from "./apartSourceImg/central-street-apartment";
import { muzaApаrtment } from "./apartSourceImg/muza-аpаrtment";
import { artPоintApartment } from "./apartSourceImg/art-роint-араrtmеnt";
import { fаmilyApаrtmentOсeаnis } from "./apartSourceImg/fаmily-аpаrtment-oсeаnis";
import { eleganceApartment } from "./apartSourceImg/elegance-apartment";

import { greenApartmentCityViewRecommendations } from "./apartSourceRecommendations/green-apartment-city-view";
import { marshmallowStudioRecommendations } from "./apartSourceRecommendations/marshmallow-studio";
import { linerRecommendations } from "./apartSourceRecommendations/liner";
import { floraApartmentRecommendations } from "./apartSourceRecommendations/floraApartment";
import { goodVibesOnlyApartmentRecommendations } from "./apartSourceRecommendations/good-vibes-only-apartment";
import { centralStreetApartmentRecommendations } from "./apartSourceRecommendations/central-street-apartment";
import { muzaApаrtmentRecommendations } from "./apartSourceRecommendations/muza-аpаrtment";
import { artPоintApartmentRecommendations } from "./apartSourceRecommendations/art-роint-араrtmеnt";



export const apartmentsPage = [
  // Green apartment city view
  {
    lot: '122233',
    pathname: 'green-apartment-city-view',
    name: 'Green apartment city view',
    amountRoom: 'студия',
    place: '23 м²',
    floor: '14 из 14',
    balcony: 'нет',
    technics: 'кондиционер, холодильник, плита, микроволновка, стиральная машина, водонагреватель, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '3',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская область, Нижний Новгород, ул. Героя Жидкова, 2',
    shortAdress: 'ул. Героя Жидкова, 2, Нижний Новгород',
    shortInfo: 'Квартира-студия, 23 м², 14/14 эт.',
    coordinatesX: 56.301713,
    coordinatesY: 43.982603,
    homeInfo: '14',
    parking: 'на улице, бесплатно для гостей',
  },
  // Marshmallow Studio
  {
    lot: '122231',
    pathname: 'marshmallow-studio',
    name: 'Marshmallow Studio',
    amountRoom: 'студия',
    place: '21 m²',
    floor: '2 из 14',
    balcony: 'нет',
    technics: 'холодильник, плита, микроволновка, стиральная машина, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '3',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская область, Нижний Новгород, Московское ш., 167к3',
    shortAdress: 'Московское шоссе, 167к3, Нижний Новгород',
    shortInfo: 'Квартира-студия, 21 м², 2/14 эт.',
    coordinatesX: 56.315775,
    coordinatesY: 43.890831,
    homeInfo: '14',
    parking: 'на улице, бесплатно для гостей',
  },
  // Лайнер
  {
    lot: '122230',
    pathname: 'liner',
    name: 'Лайнер',
    amountRoom: '1',
    place: '40 м²',
    floor: '3 из 11',
    balcony: 'нет',
    technics: 'кондиционер, холодильник, плита, микроволновка, стиральная машина, водонагреватель, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '4',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская область, Нижний Новгород, ул. Максима Горького, 24',
    shortAdress: 'ул. Максима Горького, 24, Нижний Новгород',
    shortInfo: '1-к. квартира, 40 м², 3/11 эт.',
    coordinatesX: 56.310662,
    coordinatesY: 43.980061,
    homeInfo: '11',
    parking: 'на улице, бесплатно для гостей',
  },
  // Flora apartment
  {
    lot: '122241',
    pathname: 'flora-apartment',
    name: 'Flora apartment',
    amountRoom: '2',
    place: '61 м²',
    floor: '3 из 6',
    balcony: 'балкон',
    technics: 'холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '5000 ₽',
    amountQuest: '5',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская область, Нижний Новгород, ул. Максима Горького, 45',
    shortAdress: 'ул. Максима Горького, 45, Нижний Новгород',
    shortInfo: '2-к. квартира, 61 м², 3/6 эт.',
    coordinatesX: 56.312345,
    coordinatesY: 43.983672,
    homeInfo: '6',
    parking: 'на улице, бесплатно для гостей',
  },
  // Good vibes only apartment
  {
    lot: '122242',
    pathname: 'good-vibes-only-apartment',
    name: 'Good vibes only apartment',
    amountRoom: 'студия',
    place: '26 м²',
    floor: '7 из 19',
    balcony: 'нет',
    technics: 'кондиционер, холодильник, плита, микроволновка, стиральная машина, водонагреватель, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '3',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская область, Нижний Новгород, ул. Максима Горького, 23А',
    shortAdress: ' ул. Максима Горького, 23А, Нижний Новгород',
    shortInfo: 'Квартира-студия, 26 м², 7/19 эт.',
    coordinatesX: 56.311741,
    coordinatesY: 43.977887,
    homeInfo: '19',
    parking: 'на улице, бесплатно для гостей',
  },
  // Central street apartment
  {
    lot: '122239',
    pathname: 'central-street-apartment',
    name: 'Central street apartment',
    amountRoom: '1',
    place: '42 м²',
    floor: '3 из 6',
    balcony: 'балкон',
    technics: 'кондиционер, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '4',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская область, Нижний Новгород, ул Большая Покровская, 18А',
    shortAdress: 'ул Большая Покровская, 18А, Нижний Новгород',
    shortInfo: '1-к. квартира, 42 м², 3/6 эт.',
    coordinatesX: 56.322779,
    coordinatesY: 44.002788,
    homeInfo: '6',
    parking: 'на улице, бесплатно для гостей',
  },
  // Muza аpаrtment
  {
    lot: '149983',
    pathname: 'muza-apartment',
    name: 'Muza аpаrtment',
    amountRoom: 'студия',
    place: '32 м²',
    floor: '17 из 19',
    balcony: 'нет',
    technics: 'холодильник, плита, микроволновка, стиральная машина, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '4',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская область, Нижний Новгород, ул. Максима Горького, 23А',
    shortAdress: 'ул. Максима Горького, 23А, Нижний Новгород',
    shortInfo: 'Квартира-студия, 32 м², 17/19 эт.',
    coordinatesX: 56.311741,
    coordinatesY: 43.977887,
    homeInfo: '19',
    parking: 'на улице, бесплатно для гостей',
  },
  // Аrt роint араrtmеnt
  {
    lot: '136044',
    pathname: 'art-point-apartment',
    name: 'Аrt роint араrtmеnt',
    amountRoom: '1',
    place: '41 м²',
    floor: '11 из 17',
    balcony: 'балкон',
    technics: 'кондиционер, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '4',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'ды',
    adress: 'Нижегородская область, Нижний Новгород, ул. Тимирязева, 7к5',
    shortAdress: 'ул. Тимирязева, 7к5, Нижний Новгород',
    shortInfo: '1-к. квартира, 41 м², 11/17 эт.',
    coordinatesX: 56.305299,
    coordinatesY: 43.999860,
    homeInfo: '17',
    parking: 'на улице, бесплатно для гостей',
  },
  // Fаmily аpаrtment Oсeаnis
  {
    lot: '176663',
    pathname: 'family-apartment-oceanis',
    name: 'Fаmily аpаrtment Oсeаnis',
    amountRoom: '1',
    place: '45 м²',
    floor: '1 из 10',
    balcony: 'нет',
    technics: 'кондиционер, холодильник, плита, микроволновка, стиральная машина, водонагреватель, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '4',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская обл., Нижний Новгород, Краснозвёздная ул., 29',
    shortAdress: 'ул. Краснозвёздная, 29, Нижний Новгород',
    shortInfo: '1-к. квартира, 45 м², 1/10 эт.',
    coordinatesX: 56.284439,
    coordinatesY: 43.986053,
    homeInfo: '10',
    parking: 'на улице, бесплатно для гостей',
  },

  // Elegance apartment
  {
    lot: '178079',
    pathname: 'elegance-apartment',
    name: 'Elegance apartment',
    amountRoom: '1',
    place: '38 м²',
    floor: '12 из 17',
    balcony: 'балкон',
    technics: 'кондиционер, холодильник, плита, микроволновка, стиральная машина, посудомоечная машина, водонагреватель, телевизор, фен, утюг',
    communications: 'Wi-Fi, телевидение',
    comfort: 'постельное белье, полотенца, средства гигиены',
    deposit: '3000 ₽',
    amountQuest: '4',
    likeChildren: 'да',
    likeAnimals: 'нет',
    likeSmoke: 'нет',
    likeParty: 'нет',
    accountingDocuments: 'да',
    adress: 'Нижегородская обл., Нижний Новгород, ул. Тимирязева, 7к5',
    shortAdress: 'ул. Тимирязева, 7к5, Нижний Новгород ',
    shortInfo: '1-к. квартира, 38 м², 12/17 эт.',
    coordinatesX: 56.305299,
    coordinatesY: 43.999878,
    homeInfo: '17',
    parking: 'на улице, бесплатно для гостей',
  },

];

export const slidesMain = [
  // Green apartment city view
  {
    name: 'Green apartment city view',
    slide: greenApartmentCityViewSlide,
    lot: '122233',
    endpoint: 'green-apartment-city-view',
    price: 'от 2 900 р/сут',
    shortAdress: 'ул. Героя Жидкова, 2, Нижний Новгород',
    shortInfo: 'Квартира-студия, 23м², 14/14 эт.',
    galleryApartSlider: greenApartmentCityView,
    ostrovokru: 'https://ostrovok.ru/hotel/russia/nizhniy_novgorod/mid10437078/',
    sutocnoru: 'https://sutochno.ru/front/searchapp/detail/1030617',
    flatsharing: 'https://web.flatsharing.mobi/apartments/drvODwbYoQVRHIep4UFN',
    recommendations: greenApartmentCityViewRecommendations,
  },
  // Marshmallow Studio
  {
    name: 'Marshmallow Studio',
    slide: marshmallowStudioSlide,
    lot: '122231',
    endpoint: 'marshmallow-studio',
    price: 'от 2 500 р/сут',
    shortAdress: 'Московское шоссе, 167к3, Нижний Новгород',
    shortInfo: 'Квартира-студия, 21м², 2/14 эт.',
    galleryApartSlider: marshmallowStudio,
    ostrovokru: 'https://ostrovok.ru/hotel/russia/nizhniy_novgorod/mid10437077/',
    sutocnoru: 'https://sutochno.ru/front/searchapp/detail/1308731',
    flatsharing: 'https://web.flatsharing.mobi/apartments/EQ3ZZExBk0P4mMsSgsug',
    recommendations: marshmallowStudioRecommendations,
  },
  // Лайнер
  {
    name: 'Лайнер',
    slide: linerSlide,
    lot: '122230',
    endpoint: 'liner',
    price: 'от 4 000 р/сут',
    shortAdress: 'ул. Максима Горького, 24, Нижний Новгород',
    shortInfo: '1-к. квартира, 40м², 3/11 эт.',
    galleryApartSlider: liner,
    ostrovokru: 'https://ostrovok.ru/hotel/russia/nizhniy_novgorod/mid10273089/',
    sutocnoru: 'https://sutochno.ru/front/searchapp/detail/1408879',
    flatsharing: 'https://web.flatsharing.mobi/apartments/Tqs4yRIwpd77AF9cCDpU',
    recommendations: linerRecommendations,
  },
  // Flora apartment
  {
    name: 'Flora apartment',
    slide: floraApartmentSlide,
    lot: '122241',
    endpoint: 'flora-apartment',
    price: 'от 6 000 р/сут',
    shortAdress: 'ул. Максима Горького, 45, Нижний Новгород',
    shortInfo: '2-к. квартира, 61м², 3/6 эт.',
    galleryApartSlider: floraApartment,
    ostrovokru: 'https://ostrovok.ru/hotel/russia/nizhniy_novgorod/mid10273085/',
    sutocnoru: 'https://sutochno.ru/front/searchapp/detail/1383187',
    flatsharing: 'https://web.flatsharing.mobi/apartments/QpJewQBkZDHe1vxhJxUa',
    recommendations: floraApartmentRecommendations,
  },
  // Good vibes only apartment
  {
    name: 'Good vibes only apartment',
    slide: goodVibesOnlyApartmentSlide,
    lot: '122242',
    endpoint: 'good-vibes-only-apartment',
    price: 'от 3 000 р/сут',
    shortAdress: ' ул. Максима Горького, 23А, Нижний Новгород',
    shortInfo: 'Квартира-студия, 26м², 7/19 эт.',
    galleryApartSlider: goodVibesOnlyApartment,
    ostrovokru: 'https://ostrovok.ru/hotel/russia/nizhniy_novgorod/mid10273086/',
    sutocnoru: 'https://sutochno.ru/front/searchapp/detail/1385827',
    flatsharing: 'https://web.flatsharing.mobi/apartments/bmU09FobGWNYbQMCABWf',
    recommendations: goodVibesOnlyApartmentRecommendations,
  },
  // Central street apartment
  {
    name: 'Central street apartment',
    slide: centralStreetApartmentSlide,
    lot: '122239',
    endpoint: 'central-street-apartment',
    price: 'от 4 000 р/сут',
    shortAdress: 'ул Большая Покровская, 18А, Нижний Новгород',
    shortInfo: '1-к. квартира, 42м², 3/6 эт.',
    galleryApartSlider: centralStreetApartment,
    ostrovokru: 'https://ostrovok.ru/hotel/russia/nizhniy_novgorod/mid10437085/',
    sutocnoru: 'https://sutochno.ru/front/searchapp/detail/1411553',
    flatsharing: '',
    recommendations: centralStreetApartmentRecommendations,
  },
  // Аrt роint араrtmеnt
  {
    name: 'Аrt роint араrtmеnt',
    slide: artAоintApartmentSlide,
    lot: '136044',
    endpoint: 'art-point-apartment',
    price: 'от 4 000 р/сут',
    shortAdress: 'ул. Тимирязева, 7к5, Нижний Новгород',
    shortInfo: '1-к. квартира, 41м², 11/17 эт.',
    galleryApartSlider: artPоintApartment,
    ostrovokru: 'https://ostrovok.ru/hotel/russia/nizhniy_novgorod/mid10572857/',
    sutocnoru: 'https://sutochno.ru/front/searchapp/detail/1503448',
    flatsharing: 'https://web.flatsharing.mobi/apartments/c8hTNCBRvLwqnMp68wGN',
    recommendations: artPоintApartmentRecommendations,
  },
  // Muza аpаrtment
  {
    name: 'Muza аpаrtment',
    slide: muzaApаrtmentSlide,
    lot: '149983',
    endpoint: 'muza-apartment',
    price: 'от 3 000 р/сут',
    shortAdress: 'ул. Максима Горького, 23А, Нижний Новгород',
    shortInfo: 'Квартира-студия, 32м², 17/19 эт.',
    galleryApartSlider: muzaApаrtment,
    ostrovokru: 'https://ostrovok.ru/hotel/russia/nizhniy_novgorod/mid10677609/',
    sutocnoru: 'https://sutochno.ru/front/searchapp/detail/1592436',
    flatsharing: 'https://web.flatsharing.mobi/apartments/X2dqnPPCEQXQB8V3hOcu',
    recommendations: muzaApаrtmentRecommendations,
  },
  // Fаmily аpаrtment Oсeаnis
  {
    name: 'Fаmily аpаrtment Oсeаnis',
    slide: fаmilyApаrtmentOсeаnisSlide,
    lot: '176663',
    endpoint: 'family-apartment-oceanis',
    price: 'от 3 000 р/сут',
    shortAdress: 'ул. Краснозвёздная, 29, Нижний Новгород',
    shortInfo: '1-к. квартира, 45 м², 1/10 эт.',
    galleryApartSlider: fаmilyApаrtmentOсeаnis,
    ostrovokru: 'https://goodvibesonly.ru/404',
    sutocnoru: 'https://goodvibesonly.ru/404',
    flatsharing: 'https://m.flatsharing.link/shared/nd6RJeLh419gKscG6',
    recommendations: artPоintApartmentRecommendations,
  },
  // Elegance apartment
  {
    name: 'Elegance apartment',
    slide: eleganceApartmentSlide,
    lot: '178079',
    endpoint: 'elegance-apartment',
    price: 'от 3 000 р/сут',
    shortAdress: 'ул. Тимирязева, 7к5, Нижний Новгород',
    shortInfo: '1-к. квартира, 38 м², 12/17 эт.',
    galleryApartSlider: eleganceApartment,
    ostrovokru: 'https://goodvibesonly.ru/404',
    sutocnoru: 'https://goodvibesonly.ru/404',
    flatsharing: 'https://m.flatsharing.link/shared/G9TFpAAGFUkj12we8',
    recommendations: artPоintApartmentRecommendations,
  },
];
