import eleganceApartment001 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-01.webp';
import eleganceApartment002 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-02.webp';
import eleganceApartment003 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-03.webp';
import eleganceApartment004 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-04.webp';
import eleganceApartment005 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-05.webp';
import eleganceApartment006 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-06.webp';
import eleganceApartment007 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-07.webp';
import eleganceApartment008 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-08.webp';
import eleganceApartment009 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-09.webp';
import eleganceApartment010 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-10.webp';
import eleganceApartment011 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-11.webp';
import eleganceApartment012 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-12.webp';
import eleganceApartment013 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-13.webp';
import eleganceApartment014 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-14.webp';
import eleganceApartment015 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-15.webp';
import eleganceApartment016 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-16.webp';
import eleganceApartment017 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-17.webp';
import eleganceApartment018 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-18.webp';
import eleganceApartment019 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-19.webp';
import eleganceApartment020 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-20.webp';
import eleganceApartment021 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-21.webp';
import eleganceApartment022 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-22.webp';
import eleganceApartment023 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-23.webp';
import eleganceApartment024 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-24.webp';
import eleganceApartment025 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-25.webp';
import eleganceApartment026 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-26.webp';
import eleganceApartment027 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-27.webp';
import eleganceApartment028 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-28.webp';
import eleganceApartment029 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-29.webp';
import eleganceApartment030 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-30.webp';
import eleganceApartment031 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-31.webp';
import eleganceApartment032 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-32.webp';
import eleganceApartment033 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-33.webp';
import eleganceApartment034 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-34.webp';
import eleganceApartment035 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-35.webp';
import eleganceApartment036 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-36.webp';
import eleganceApartment037 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-37.webp';
import eleganceApartment038 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-38.webp';
import eleganceApartment039 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-39.webp';
import eleganceApartment040 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-40.webp';

import eleganceApartmentPrew001 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-01.jpg';
import eleganceApartmentPrew002 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-02.jpg';
import eleganceApartmentPrew003 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-03.jpg';
import eleganceApartmentPrew004 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-04.jpg';
import eleganceApartmentPrew005 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-05.jpg';
import eleganceApartmentPrew006 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-06.jpg';
import eleganceApartmentPrew007 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-07.jpg';
import eleganceApartmentPrew008 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-08.jpg';
import eleganceApartmentPrew009 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-09.jpg';
import eleganceApartmentPrew010 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-10.jpg';
import eleganceApartmentPrew011 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-11.jpg';
import eleganceApartmentPrew012 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-12.jpg';
import eleganceApartmentPrew013 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-13.jpg';
import eleganceApartmentPrew014 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-14.jpg';
import eleganceApartmentPrew015 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-15.jpg';
import eleganceApartmentPrew016 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-16.jpg';
import eleganceApartmentPrew017 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-17.jpg';
import eleganceApartmentPrew018 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-18.jpg';
import eleganceApartmentPrew019 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-19.jpg';
import eleganceApartmentPrew020 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-20.jpg';
import eleganceApartmentPrew021 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-21.jpg';
import eleganceApartmentPrew022 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-22.jpg';
import eleganceApartmentPrew023 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-23.jpg';
import eleganceApartmentPrew024 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-24.jpg';
import eleganceApartmentPrew025 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-25.jpg';
import eleganceApartmentPrew026 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-26.jpg';
import eleganceApartmentPrew027 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-27.jpg';
import eleganceApartmentPrew028 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-28.jpg';
import eleganceApartmentPrew029 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-29.jpg';
import eleganceApartmentPrew030 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-30.jpg';
import eleganceApartmentPrew031 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-31.jpg';
import eleganceApartmentPrew032 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-32.jpg';
import eleganceApartmentPrew033 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-33.jpg';
import eleganceApartmentPrew034 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-34.jpg';
import eleganceApartmentPrew035 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-35.jpg';
import eleganceApartmentPrew036 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-36.jpg';
import eleganceApartmentPrew037 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-37.jpg';
import eleganceApartmentPrew038 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-38.jpg';
import eleganceApartmentPrew039 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-39.jpg';
import eleganceApartmentPrew040 from '../../img/gallery-apartments/elegance-apartment/elegance-apartment-prw/elegance-apartment-prew-40.jpg';

export const eleganceApartment = [
  { key: 'eleganceApartment001', image: eleganceApartment001, preview: eleganceApartmentPrew001, },
  { key: 'eleganceApartment002', image: eleganceApartment002, preview: eleganceApartmentPrew002, },
  { key: 'eleganceApartment003', image: eleganceApartment003, preview: eleganceApartmentPrew003, },
  { key: 'eleganceApartment004', image: eleganceApartment004, preview: eleganceApartmentPrew004, },
  { key: 'eleganceApartment005', image: eleganceApartment005, preview: eleganceApartmentPrew005, },
  { key: 'eleganceApartment006', image: eleganceApartment006, preview: eleganceApartmentPrew006, },
  { key: 'eleganceApartment007', image: eleganceApartment007, preview: eleganceApartmentPrew007, },
  { key: 'eleganceApartment008', image: eleganceApartment008, preview: eleganceApartmentPrew008, },
  { key: 'eleganceApartment009', image: eleganceApartment009, preview: eleganceApartmentPrew009, },
  { key: 'eleganceApartment010', image: eleganceApartment010, preview: eleganceApartmentPrew010, },
  { key: 'eleganceApartment011', image: eleganceApartment011, preview: eleganceApartmentPrew011, },
  { key: 'eleganceApartment012', image: eleganceApartment012, preview: eleganceApartmentPrew012, },
  { key: 'eleganceApartment013', image: eleganceApartment013, preview: eleganceApartmentPrew013, },
  { key: 'eleganceApartment014', image: eleganceApartment014, preview: eleganceApartmentPrew014, },
  { key: 'eleganceApartment015', image: eleganceApartment015, preview: eleganceApartmentPrew015, },
  { key: 'eleganceApartment016', image: eleganceApartment016, preview: eleganceApartmentPrew016, },
  { key: 'eleganceApartment017', image: eleganceApartment017, preview: eleganceApartmentPrew017, },
  { key: 'eleganceApartment018', image: eleganceApartment018, preview: eleganceApartmentPrew018, },
  { key: 'eleganceApartment019', image: eleganceApartment019, preview: eleganceApartmentPrew019, },
  { key: 'eleganceApartment020', image: eleganceApartment020, preview: eleganceApartmentPrew020, },
  { key: 'eleganceApartment021', image: eleganceApartment021, preview: eleganceApartmentPrew021, },
  { key: 'eleganceApartment022', image: eleganceApartment022, preview: eleganceApartmentPrew022, },
  { key: 'eleganceApartment023', image: eleganceApartment023, preview: eleganceApartmentPrew023, },
  { key: 'eleganceApartment024', image: eleganceApartment024, preview: eleganceApartmentPrew024, },
  { key: 'eleganceApartment025', image: eleganceApartment025, preview: eleganceApartmentPrew025, },
  { key: 'eleganceApartment026', image: eleganceApartment026, preview: eleganceApartmentPrew026, },
  { key: 'eleganceApartment027', image: eleganceApartment027, preview: eleganceApartmentPrew027, },
  { key: 'eleganceApartment028', image: eleganceApartment028, preview: eleganceApartmentPrew028, },
  { key: 'eleganceApartment029', image: eleganceApartment029, preview: eleganceApartmentPrew029, },
  { key: 'eleganceApartment030', image: eleganceApartment030, preview: eleganceApartmentPrew030, },
  { key: 'eleganceApartment031', image: eleganceApartment031, preview: eleganceApartmentPrew031, },
  { key: 'eleganceApartment032', image: eleganceApartment032, preview: eleganceApartmentPrew032, },
  { key: 'eleganceApartment033', image: eleganceApartment033, preview: eleganceApartmentPrew033, },
  { key: 'eleganceApartment034', image: eleganceApartment034, preview: eleganceApartmentPrew034, },
  { key: 'eleganceApartment035', image: eleganceApartment035, preview: eleganceApartmentPrew035, },
  { key: 'eleganceApartment036', image: eleganceApartment036, preview: eleganceApartmentPrew036, },
  { key: 'eleganceApartment037', image: eleganceApartment037, preview: eleganceApartmentPrew037, },
  { key: 'eleganceApartment038', image: eleganceApartment038, preview: eleganceApartmentPrew038, },
  { key: 'eleganceApartment039', image: eleganceApartment039, preview: eleganceApartmentPrew039, },
  { key: 'eleganceApartment040', image: eleganceApartment040, preview: eleganceApartmentPrew040, },
]