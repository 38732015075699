import person1 from '../img/icon-persons/person1.jpg';
import person2 from '../img/icon-persons/person2.jpg';
import person3 from '../img/icon-persons/person3.jpg';
import person4 from '../img/icon-persons/person4.jpg';
import person5 from '../img/icon-persons/person5.jpg';
import person6 from '../img/icon-persons/person6.jpg';
import person7 from '../img/icon-persons/person7.jpg';
import person8 from '../img/icon-persons/person8.jpg';
import person9 from '../img/icon-persons/person9.jpg';
import person10 from '../img/icon-persons/person10.jpg';
import person11 from '../img/icon-persons/person11.jpg';
import person12 from '../img/icon-persons/person12.jpg';
import person13 from '../img/icon-persons/person13.jpg';
import person14 from '../img/icon-persons/person14.jpg';
import person15 from '../img/icon-persons/person15.jpg';
import person16 from '../img/icon-persons/person16.jpg';
import person17 from '../img/icon-persons/person17.jpg';
import person18 from '../img/icon-persons/person18.jpg';
import person19 from '../img/icon-persons/person19.jpg';
import person20 from '../img/icon-persons/person20.jpg';
import person21 from '../img/icon-persons/person21.jpg';
import person22 from '../img/icon-persons/person22.jpg';
import person23 from '../img/icon-persons/person23.jpg';

export const sourcePersons = [
  { image: person1, },
  { image: person2, },
  { image: person3, },
  { image: person4, },
  { image: person5, },
  { image: person6, },
  { image: person7, },
  { image: person8, },
  { image: person9, },
  { image: person10, },
  { image: person11, },
  { image: person12, },
  { image: person13, },
  { image: person14, },
  { image: person15, },
  { image: person16, },
  { image: person17, },
  { image: person18, },
  { image: person19, },
  { image: person20, },
  { image: person21, },
  { image: person22, },
  { image: person23, },
];








