import artPоintApartment001 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-001.jpg';
import artPоintApartment002 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-002.jpg';
import artPоintApartment003 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-003.jpg';
import artPоintApartment004 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-004.jpg';
import artPоintApartment005 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-005.jpg';
import artPоintApartment006 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-006.jpg';
import artPоintApartment007 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-007.jpg';
import artPоintApartment008 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-008.jpg';
import artPоintApartment009 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-009.jpg';
import artPоintApartment010 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-010.jpg';
import artPоintApartment011 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-011.jpg';
import artPоintApartment012 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-012.jpg';
import artPоintApartment013 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-013.jpg';
import artPоintApartment014 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-014.jpg';
import artPоintApartment015 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-015.jpg';
import artPоintApartment016 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-016.jpg';
import artPоintApartment017 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-017.jpg';
import artPоintApartment018 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-018.jpg';
import artPоintApartment019 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-019.jpg';
import artPоintApartment020 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-020.jpg';
import artPоintApartment021 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-021.jpg';
import artPоintApartment022 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-022.jpg';
import artPоintApartment023 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-023.jpg';
import artPоintApartment024 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-024.jpg';
import artPоintApartment025 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-025.jpg';
import artPоintApartment026 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-026.jpg';
import artPоintApartment027 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-027.jpg';
import artPоintApartment028 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-028.jpg';
import artPоintApartment029 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-029.jpg';
import artPоintApartment030 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-030.jpg';
import artPоintApartment031 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-031.jpg';
import artPоintApartment032 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-032.jpg';
import artPоintApartment033 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-033.jpg';


import artPоintApartmentPrew001 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-001.jpg';
import artPоintApartmentPrew002 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-002.jpg';
import artPоintApartmentPrew003 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-003.jpg';
import artPоintApartmentPrew004 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-004.jpg';
import artPоintApartmentPrew005 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-005.jpg';
import artPоintApartmentPrew006 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-006.jpg';
import artPоintApartmentPrew007 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-007.jpg';
import artPоintApartmentPrew008 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-008.jpg';
import artPоintApartmentPrew009 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-009.jpg';
import artPоintApartmentPrew010 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-010.jpg';
import artPоintApartmentPrew011 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-011.jpg';
import artPоintApartmentPrew012 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-012.jpg';
import artPоintApartmentPrew013 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-013.jpg';
import artPоintApartmentPrew014 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-014.jpg';
import artPоintApartmentPrew015 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-015.jpg';
import artPоintApartmentPrew016 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-016.jpg';
import artPоintApartmentPrew017 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-017.jpg';
import artPоintApartmentPrew018 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-018.jpg';
import artPоintApartmentPrew019 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-019.jpg';
import artPоintApartmentPrew020 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-020.jpg';
import artPоintApartmentPrew021 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-021.jpg';
import artPоintApartmentPrew022 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-022.jpg';
import artPоintApartmentPrew023 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-023.jpg';
import artPоintApartmentPrew024 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-024.jpg';
import artPоintApartmentPrew025 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-025.jpg';
import artPоintApartmentPrew026 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-026.jpg';
import artPоintApartmentPrew027 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-027.jpg';
import artPоintApartmentPrew028 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-028.jpg';
import artPоintApartmentPrew029 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-029.jpg';
import artPоintApartmentPrew030 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-030.jpg';
import artPоintApartmentPrew031 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-031.jpg';
import artPоintApartmentPrew032 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-032.jpg';
import artPоintApartmentPrew033 from '../../img/gallery-apartments/art-роint-араrtmеnt/art-роint-араrtmеnt-prw/art-роint-араrtmеnt-prew-033.jpg';

export const artPоintApartment = [
  { key: 'artPоintApartment001', image: artPоintApartment001, preview: artPоintApartmentPrew001, },
  { key: 'artPоintApartment002', image: artPоintApartment002, preview: artPоintApartmentPrew002, },
  { key: 'artPоintApartment003', image: artPоintApartment003, preview: artPоintApartmentPrew003, },
  { key: 'artPоintApartment004', image: artPоintApartment004, preview: artPоintApartmentPrew004, },
  { key: 'artPоintApartment005', image: artPоintApartment005, preview: artPоintApartmentPrew005, },
  { key: 'artPоintApartment006', image: artPоintApartment006, preview: artPоintApartmentPrew006, },
  { key: 'artPоintApartment007', image: artPоintApartment007, preview: artPоintApartmentPrew007, },
  { key: 'artPоintApartment008', image: artPоintApartment008, preview: artPоintApartmentPrew008, },
  { key: 'artPоintApartment009', image: artPоintApartment009, preview: artPоintApartmentPrew009, },
  { key: 'artPоintApartment010', image: artPоintApartment010, preview: artPоintApartmentPrew010, },
  { key: 'artPоintApartment011', image: artPоintApartment011, preview: artPоintApartmentPrew011, },
  { key: 'artPоintApartment012', image: artPоintApartment012, preview: artPоintApartmentPrew012, },
  { key: 'artPоintApartment013', image: artPоintApartment013, preview: artPоintApartmentPrew013, },
  { key: 'artPоintApartment014', image: artPоintApartment014, preview: artPоintApartmentPrew014, },
  { key: 'artPоintApartment015', image: artPоintApartment015, preview: artPоintApartmentPrew015, },
  { key: 'artPоintApartment016', image: artPоintApartment016, preview: artPоintApartmentPrew016, },
  { key: 'artPоintApartment017', image: artPоintApartment017, preview: artPоintApartmentPrew017, },
  { key: 'artPоintApartment018', image: artPоintApartment018, preview: artPоintApartmentPrew018, },
  { key: 'artPоintApartment019', image: artPоintApartment019, preview: artPоintApartmentPrew019, },
  { key: 'artPоintApartment020', image: artPоintApartment020, preview: artPоintApartmentPrew020, },
  { key: 'artPоintApartment021', image: artPоintApartment021, preview: artPоintApartmentPrew021, },
  { key: 'artPоintApartment022', image: artPоintApartment022, preview: artPоintApartmentPrew022, },
  { key: 'artPоintApartment023', image: artPоintApartment023, preview: artPоintApartmentPrew023, },
  { key: 'artPоintApartment024', image: artPоintApartment024, preview: artPоintApartmentPrew024, },
  { key: 'artPоintApartment025', image: artPоintApartment025, preview: artPоintApartmentPrew025, },
  { key: 'artPоintApartment026', image: artPоintApartment026, preview: artPоintApartmentPrew026, },
  { key: 'artPоintApartment027', image: artPоintApartment027, preview: artPоintApartmentPrew027, },
  { key: 'artPоintApartment028', image: artPоintApartment028, preview: artPоintApartmentPrew028, },
  { key: 'artPоintApartment029', image: artPоintApartment029, preview: artPоintApartmentPrew029, },
  { key: 'artPоintApartment030', image: artPоintApartment030, preview: artPоintApartmentPrew030, },
  { key: 'artPоintApartment031', image: artPоintApartment031, preview: artPоintApartmentPrew031, },
  { key: 'artPоintApartment032', image: artPоintApartment032, preview: artPоintApartmentPrew032, },
  { key: 'artPоintApartment033', image: artPоintApartment033, preview: artPоintApartmentPrew033, },

]