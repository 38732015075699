import { sourcePersons } from '../sourcePersons';

export const muzaApаrtmentRecommendations = [
  {
    key: 'muzaApаrtmentRecommendations001', image: sourcePersons,
    text: 'Все прошло хорошо, приятные арендодатели.',
  },
  {
    key: 'muzaApаrtmentRecommendations002', image: sourcePersons,
    text: 'Всё понравилось,отличная квартира,точь в точь как на фото. Расположение дома тоже хорошее. Впервые снимали квартиру с бесконтактным заселением,было страшновато)) Но всё прошло отлично,Екатерина была всегда на связи. В квартире всё чисто и красиво,хороший ремонт, есть всё,что необходимо для проживания. Были семьёй,с ребёнком,удобно что диван раскладывается, тесно не было)',
  },
  {
    key: 'muzaApаrtmentRecommendations003', image: sourcePersons,
    text: 'Милая и уютная квартирка, все что нужно есть. Расположение хорошее. Заселение состоялось и все прошло хорошо. Спасибо )',
  },
  {
    key: 'muzaApаrtmentRecommendations004', image: sourcePersons,
    text: 'Все понравилось. Апартаменты как на фото. На кухне и в квартире есть все необходимое вплоть до швейного набора Единственный небольшой минус - парковка. Очень тесная и загруженная. Приходилось тратить много времени, чтобы никого не помять. Но это не испортило впечатление от проживания Отдельно хочу отметить вид. Каждое утро смотрели с ребёнком рассвет, очень красиво',
  },
  {
    key: 'muzaApаrtmentRecommendations005', image: sourcePersons,
    text: 'Квартира супер!!! Очень чистая, современная, есть всё необходимое! По организации тоже всё отлично!',
  },
  {
    key: 'muzaApаrtmentRecommendations006', image: sourcePersons,
    text: 'Останавливались семьей 3 человека на 5 дней. Оказались приятно удивлены наполнением: есть буквально все от круп до гигиенических наборов. Это первая квартира, где не поскупились на матрац и подушки. В общем квартиру рекомендую.',
  },
  {
    key: 'muzaApаrtmentRecommendations007', image: sourcePersons,
    text: 'Уютная чистая студия с хорошим видом. Все продумано до мелочей, оттого очень приятно и чувствуется забота. Наброски на стене и мягкий свет создают атмосферу. Нам было комфортно, общение и заселение прошло легко, есть парковка. Удобная локация. Рекомендую!',
  },
  {
    key: 'muzaApаrtmentRecommendations008', image: sourcePersons,
    text: 'Отличная квартира, полностью соответствует описанию. Есть все необходимое для комфортного проживания и даже больше) Екатерина оперативно реагирует на возникающие вопросы. При следующей поездке в Нижний Новгород только сюда. Однозначно рекомендую 👍',
  },
  {
    key: 'muzaApаrtmentRecommendations009', image: sourcePersons,
    text: 'Останавливались на две ночи. Прекрасные апартаменты, свежий ремонт, идеально чистое белье. Продумано всё до мелочей (есть и швейный набор и хорошая аптечка, и какие то продукты если не успели в магазин заскочить, гейзерная кофеварка и тд и тп). Смело рекомендую для проживания в Нижнем Новгороде!',
  },

];