import marshmallowStudio001 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-001.jpg';
import marshmallowStudio002 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-002.jpg';
import marshmallowStudio003 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-003.jpg';
import marshmallowStudio004 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-004.jpg';
import marshmallowStudio005 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-005.jpg';
import marshmallowStudio006 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-006.jpg';
import marshmallowStudio007 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-007.jpg';
import marshmallowStudio008 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-008.jpg';
import marshmallowStudio009 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-009.jpg';
import marshmallowStudio010 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-010.jpg';
import marshmallowStudio011 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-011.jpg';
import marshmallowStudio012 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-012.jpg';
import marshmallowStudio013 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-013.jpg';
import marshmallowStudio014 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-014.jpg';
import marshmallowStudio015 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-015.jpg';
import marshmallowStudio016 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-016.jpg';
import marshmallowStudio017 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-017.jpg';
import marshmallowStudio018 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-018.jpg';
import marshmallowStudio019 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-019.jpg';
import marshmallowStudio020 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-020.jpg';
import marshmallowStudio021 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-021.jpg';
import marshmallowStudio022 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-022.jpg';
import marshmallowStudio023 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-023.jpg';
import marshmallowStudio024 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-024.jpg';
import marshmallowStudio025 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-025.jpg';
import marshmallowStudio026 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-026.jpg';
import marshmallowStudio027 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-027.jpg';
import marshmallowStudio028 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-028.jpg';
import marshmallowStudio029 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-029.jpg';
import marshmallowStudio030 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-030.jpg';
import marshmallowStudio031 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-031.jpg';


import marshmallowStudioPrew001 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-001.jpg';
import marshmallowStudioPrew002 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-002.jpg';
import marshmallowStudioPrew003 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-003.jpg';
import marshmallowStudioPrew004 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-004.jpg';
import marshmallowStudioPrew005 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-005.jpg';
import marshmallowStudioPrew006 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-006.jpg';
import marshmallowStudioPrew007 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-007.jpg';
import marshmallowStudioPrew008 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-008.jpg';
import marshmallowStudioPrew009 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-009.jpg';
import marshmallowStudioPrew010 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-010.jpg';
import marshmallowStudioPrew011 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-011.jpg';
import marshmallowStudioPrew012 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-012.jpg';
import marshmallowStudioPrew013 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-013.jpg';
import marshmallowStudioPrew014 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-014.jpg';
import marshmallowStudioPrew015 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-015.jpg';
import marshmallowStudioPrew016 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-016.jpg';
import marshmallowStudioPrew017 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-017.jpg';
import marshmallowStudioPrew018 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-018.jpg';
import marshmallowStudioPrew019 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-019.jpg';
import marshmallowStudioPrew020 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-020.jpg';
import marshmallowStudioPrew021 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-021.jpg';
import marshmallowStudioPrew022 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-022.jpg';
import marshmallowStudioPrew023 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-023.jpg';
import marshmallowStudioPrew024 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-024.jpg';
import marshmallowStudioPrew025 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-025.jpg';
import marshmallowStudioPrew026 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-026.jpg';
import marshmallowStudioPrew027 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-027.jpg';
import marshmallowStudioPrew028 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-028.jpg';
import marshmallowStudioPrew029 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-029.jpg';
import marshmallowStudioPrew030 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-030.jpg';
import marshmallowStudioPrew031 from '../../img/gallery-apartments/marshmallow-studio/marshmallow-studio-prw/marshmallow-studio-prew-031.jpg';





export const marshmallowStudio = [
  { key: 'marshmallowStudio001', image: marshmallowStudio001, preview: marshmallowStudioPrew001, },
  { key: 'marshmallowStudio002', image: marshmallowStudio002, preview: marshmallowStudioPrew002, },
  { key: 'marshmallowStudio003', image: marshmallowStudio003, preview: marshmallowStudioPrew003, },
  { key: 'marshmallowStudio004', image: marshmallowStudio004, preview: marshmallowStudioPrew004, },
  { key: 'marshmallowStudio005', image: marshmallowStudio005, preview: marshmallowStudioPrew005, },
  { key: 'marshmallowStudio006', image: marshmallowStudio006, preview: marshmallowStudioPrew006, },
  { key: 'marshmallowStudio007', image: marshmallowStudio007, preview: marshmallowStudioPrew007, },
  { key: 'marshmallowStudio008', image: marshmallowStudio008, preview: marshmallowStudioPrew008, },
  { key: 'marshmallowStudio009', image: marshmallowStudio009, preview: marshmallowStudioPrew009, },
  { key: 'marshmallowStudio010', image: marshmallowStudio010, preview: marshmallowStudioPrew010, },
  { key: 'marshmallowStudio011', image: marshmallowStudio011, preview: marshmallowStudioPrew011, },
  { key: 'marshmallowStudio012', image: marshmallowStudio012, preview: marshmallowStudioPrew012, },
  { key: 'marshmallowStudio013', image: marshmallowStudio013, preview: marshmallowStudioPrew013, },
  { key: 'marshmallowStudio014', image: marshmallowStudio014, preview: marshmallowStudioPrew014, },
  { key: 'marshmallowStudio015', image: marshmallowStudio015, preview: marshmallowStudioPrew015, },
  { key: 'marshmallowStudio016', image: marshmallowStudio016, preview: marshmallowStudioPrew016, },
  { key: 'marshmallowStudio017', image: marshmallowStudio017, preview: marshmallowStudioPrew017, },
  { key: 'marshmallowStudio018', image: marshmallowStudio018, preview: marshmallowStudioPrew018, },
  { key: 'marshmallowStudio019', image: marshmallowStudio019, preview: marshmallowStudioPrew019, },
  { key: 'marshmallowStudio020', image: marshmallowStudio020, preview: marshmallowStudioPrew020, },
  { key: 'marshmallowStudio021', image: marshmallowStudio021, preview: marshmallowStudioPrew021, },
  { key: 'marshmallowStudio022', image: marshmallowStudio022, preview: marshmallowStudioPrew022, },
  { key: 'marshmallowStudio023', image: marshmallowStudio023, preview: marshmallowStudioPrew023, },
  { key: 'marshmallowStudio024', image: marshmallowStudio024, preview: marshmallowStudioPrew024, },
  { key: 'marshmallowStudio025', image: marshmallowStudio025, preview: marshmallowStudioPrew025, },
  { key: 'marshmallowStudio026', image: marshmallowStudio026, preview: marshmallowStudioPrew026, },
  { key: 'marshmallowStudio027', image: marshmallowStudio027, preview: marshmallowStudioPrew027, },
  { key: 'marshmallowStudio028', image: marshmallowStudio028, preview: marshmallowStudioPrew028, },
  { key: 'marshmallowStudio029', image: marshmallowStudio029, preview: marshmallowStudioPrew029, },
  { key: 'marshmallowStudio030', image: marshmallowStudio030, preview: marshmallowStudioPrew030, },
  { key: 'marshmallowStudio031', image: marshmallowStudio031, preview: marshmallowStudioPrew031, },

]